import { LitElement, css, CSSResult, unsafeCSS, property, html, TemplateResult, svg, SVGTemplateResult } from 'lit-element';

const units = ['', 'K', 'M', 'G', 'T', 'P', 'E']

function SI(v: number): string {
    let divisor = 1;
    for (const unit of units) {
        const quotient = v / divisor;
        // We allow up to 2 whole digits and one remainder.
        if (quotient < 1000) {
            if (Math.floor(quotient) === quotient) {
                return `${quotient}${unit}`
            }
            return `${quotient.toFixed(1)}${unit}`
        }

        divisor *= 1000;
    }
    // If we exited the loop without finding a value
    // we should figure out how to handle this case.
    // TODO: ^
    return `${v / divisor}`
}

export class SIValue extends LitElement {
    static get styles() {
        return [
            css`
:host {
    display: inline-block;
}
`
        ];
    }

    @property()
    private value: number | undefined;

    public render() {
        if (this.value === undefined) {
            return html``
        }
        return html`${SI(this.value)}`;
    }
}
customElements.define('si-value', SIValue);