import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, AbsoluteSelector } from 'eventful-increment';
import { StyleBase } from './style-base';

import './si-value';
import './with-popover';

export interface Sortable {
    name: string;
    /**
     * sortBy is invoked to determine if a sort
     * on this is useful. The caller may use this as a signal
     * to sort data immediately or may defer to `by` during
     * an `update`.
     */
    sortBy: () => boolean;
}

export interface Sorting {
    name: string;
    ascending: boolean;
}

/**
 * ChoiceList allows generic sortable tables.
 * 
 * The underlying data model must be maintained by the implementor.
 */
export abstract class ChoiceList extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.sort-char {
    display: inline-block;
    min-width: 1.2em;
    padding-left: 0.2em;
}

.mob {
    display: flex;
    flex-direction: column;
    position: relative;
}
`
        ];
    }

    @property()
    protected ts = 0;

    @property()
    protected target: Selector | undefined;

    @property()
    protected selected: AbsoluteSelector | undefined;

    @property()
    protected by: Sorting = this.defaultSort()

    constructor() {
        super();
    }

    public render() {
        if (!this.ts || !this.target) {
            return html``
        }
        return html`
<with-popover
    .selected="${!!this.selected}"
    @unselect="${() => this.selected = undefined}">
    <table slot="main">
        <thead>
            <tr>
                <!-- Include a spacer -->
                <th></th>
                ${this.header().map(v => html`
<th class="noselect" 
@click="${() => this.sortBy(v)}"
>${v.name} ${this.sortChar(v)}</th>
                `)}
            </tr>
        </thead>
        <tbody>
            ${this.rowSet()}
        </tbody>
    </table>
    <div slot="popover">${this.card(this.selected)}</div>
</with-popover>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        // Unselect when we switch between targets
        const targetChange = changedProperties.get('target');
        if (targetChange) {
            this.selected = undefined;
        }
    }

    /**
     * defaultSort returns the Sorting that should be initially
     * applied to the table.
     * 
     * NOTE: This is invoked during initialization so it must NOT
     * rely on any element state.
     */
    protected abstract defaultSort(): Sorting;

    /**
     * header returns all Sortable of the data that should be
     * used to construct the header.
     */
    protected abstract header(): Sortable[];
    /**
     * row is called from 0 until an undefined value is received.
     * The callee MUST return an undefined value to indicate the first
     * out of bound index that was encountered.
     */
    protected abstract row(index: number): TemplateResult | undefined;
    /**
     * card returns the associated card to show on selection
     */
    protected abstract card(sel: AbsoluteSelector | undefined): TemplateResult;

    // rowSet collects all rows produced by row
    private rowSet(): TemplateResult[] {
        const set: TemplateResult[] = [];
        for (let i = 0; ; i++) {
            const row = this.row(i);
            if (!row) {
                break;
            }
            set.push(row);
        }
        return set
    }

    // sortChar returns the TemplateResult single UTF-8 used
    // to indicate the sort semantics of this table.
    private sortChar(ref: Sortable): TemplateResult {
        let char: TemplateResult | undefined;

        const { ascending } = this.by;
        // tslint:disable-next-line:prefer-conditional-expression
        if (ascending) {
            // ▲
            char = html`&#9650;`;
        } else {
            // ▼
            char = html`&#9660;`;
        }

        // If we have the wrong thing or
        // it doesn't have a name, clear the character
        if (this.by.name !== ref.name ||
            ref.name.length === 0) {
            char = undefined
        }

        return html`<div class="sort-char">${char}</div>`
    }

    private sortBy(ref: Sortable) {
        // Cause our underlying data to be sorted.
        // 
        // If this is not a useful sort, retain the existing sort
        const useful = ref.sortBy();
        if (!useful) {
            return;
        }

        // If we're already sorted by this, invert
        // the order.
        const ascending = (!!ref.name && this.by.name === ref.name) ?
            !this.by.ascending : true
        this.by = {
            name: ref.name,
            ascending,
        }
    }
}