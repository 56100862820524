import { PropertyValues, property, html, TemplateResult } from 'lit-element';
import { Selector, GetCollection, PrettyName, AvailableQuests, NewAbsoluteSelector, AbsoluteSelector, Quest, StepType, CalculateHighestCombatLevel, PrettyError } from 'eventful-increment';
import { vcs } from '../state';

import './quest-card';
import { ReadonlyDeep } from '../types/readonly-deep';

import { ChoiceList, Sorting, Sortable } from './choice-list';

export type UIQuest = ReadonlyDeep<Quest>;

export function QuestInProgress(q: UIQuest,
    activeQuest: Selector | undefined) {

    return !!activeQuest && activeQuest.id === q.id
}

export class QuestChoices extends ChoiceList {

    @property()
    private activeQuest: Selector | undefined;

    private quests: UIQuest[] = [];

    constructor() {
        super();
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }
        // Clear the chosen and active quests when
        // we switch between targets
        const targetChange = changedProperties.get('target');
        if (targetChange) {
            this.activeQuest = undefined;
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c || !c.quest) {
            this.quests = [];
            return;
        }

        this.activeQuest = c.quest.active ? c.quest.active.quest : undefined;

        const quests = AvailableQuests(s, NewAbsoluteSelector(c));
        this.quests = quests
            .map(q => {
                return GetCollection(s.quests, q);
            })
            .filter(q => q !== undefined) as UIQuest[]
    }

    protected defaultSort(): Sorting {
        return {
            name: '',
            ascending: true,
        }
    }

    protected header(): Sortable[] {
        return [
            {
                name: 'Hardest Combat',
                sortBy: () => false,
            },
            {
                name: 'Hardest Skill Check',
                sortBy: () => false,
            },
            {
                name: 'Status',
                sortBy: () => false,
            }
        ];
    }


    protected row(index: number): TemplateResult | undefined {
        // Ensure bounds are valid
        if (index >= this.quests.length) {
            return undefined;
        }

        const q = this.quests[index];
        const name = PrettyName(q);

        let inProgressString = '';
        if (QuestInProgress(q, this.activeQuest)) {
            inProgressString = 'Trying'
        }

        const s = vcs.get();

        const worstCombatLevel = q.steps.reduce((worst, step) => {
            if (step.type !== StepType.Fight) {
                return worst;
            }

            const stepLevel = CalculateHighestCombatLevel(s,
                step.enemies as AbsoluteSelector[]);
            return Math.max(stepLevel, worst);
        }, 0)

        const hardestSkillCheck = q.steps.reduce((worst, step) => {
            if (step.type !== StepType.Gather) {
                return worst;
            }

            const node = GetCollection(s.nodes, step.node);
            if (!node) {
                throw PrettyError`unknown node: ${step.node}`;
            }
            return Math.max(node.resources.difficulty, worst);
        }, 0)

        // TODO: handle requirements to disable
        // and apply the shake effect similar to farm-choices

        // TODO: we need to implement skill checks
        return html`
<tr @click="${() => this.selected = NewAbsoluteSelector(q)}">
    <td>${name}</td>
    <td>${worstCombatLevel ? worstCombatLevel : ''}</td>
    <td>${hardestSkillCheck ? hardestSkillCheck : ''}</td>
    <td>${inProgressString}</td>
</tr>
`
    }

    protected card(sel: AbsoluteSelector | undefined): TemplateResult {
        const s = vcs.get();
        let q: UIQuest | undefined;
        if (sel) {
            q = GetCollection(s.quests, sel);
        }

        return html`
<quest-card class="quest"
    .ts="${this.ts}"
    .target="${this.target}"
    .quest="${q}"
    .activeQuest="${this.activeQuest}"></quest-card>
`
    }
}
customElements.define('quest-choices', QuestChoices);