// Import the LitElement base class and html helper function
import { LitElement, html, property, PropertyValues, TemplateResult, css, CSSResult } from 'lit-element';
import { vcs } from '../state';
import { Second, Character, IntentAttrs, Selector, GetCollection, NonMatching, NewAbsoluteSelector, IntentType, Label, HasLabel, AbsoluteSelector, CommodityKey } from 'eventful-increment';
import log from '../logger';
import { StyleBase } from './style-base';

import './player-character';
import { UICharacter } from './farm-choices';

export class List extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            // We use a media query to stack player-row
            // rather than apply a grid layout for small displays.
            css`
.char {
    grid-area: char;
}

.intent {
    grid-area: intent;
}

.player-row {
   display: grid;
   grid-template-columns: 33fr 2fr 65fr;
   grid-template-rows: 100fr;
   grid-template-areas:
     "char       .      intent";
   justify-self: center;

   max-width: 80em;
}

@media only screen and (max-width: 767px) {
    .player-row {
        display: flex;
        flex-direction: column;
    }

    .player-row:not(:last-child) {
        margin-bottom: 1em;
    }
}
`
        ];
    }

    @property()
    private ts = 0;

    // filter allows optional filtering of the displayed Characters
    @property()
    private filter: Label | undefined;

    @property()
    private selected: Selector | undefined;

    private chars: UICharacter[] = [];

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``
        }
        return html`
${this.chars.map(c => this.characterRow(c))}
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        const s = vcs.get();
        const set = [];
        // TODO: introduce 'FilterLabel' in eventful-increment
        // to avoid requiring this be written out manually
        for (const c of Object.keys(s.characters)) {
            const char = s.characters[c];
            // Skip Characters which don't match our filter.
            if (this.filter && !HasLabel(char, this.filter)) {
                continue;
            }
            set.push(char);
        }
        this.chars = set;
    }

    private characterRow(c: UICharacter): TemplateResult {
        const sel = NewAbsoluteSelector(c);
        return html`<div class="player-row">
            <player-character class="char"
            .ts=${this.ts}
            .selected=${this.selected}
            .target=${sel}
            ></player-character>
            <character-intent class="intent" .ts="${this.ts}" .target="${sel}"></character-intent>
        </div>`
    }
}

// IncEventType is the type of the event associated with a detail.
// 
// When listening for an event, listen ie
// `@select-character`
// it is NOT possible to use templating for the event name. Hence,
// these are NOT safe to modify.
export enum IncEventType {
    SelectCharacter = 'select-character',
    IntentChange = 'intent-change',
    QuestStart = 'quest-start',
    ConsumableSlotSet = 'consume-slot-set'
}

export interface IncSelectDetail {
    // type is required here to allowing narrowing to a specific
    // detail.
    type: IncEventType.SelectCharacter;
    abs: AbsoluteSelector;
}

export interface IncIntentChangeDetail {
    type: IncEventType.IntentChange;
    target: Selector;
    intent: IntentAttrs;
}

export interface IncQuestStartDetail {
    type: IncEventType.QuestStart;
    subject: Selector;
    quest: AbsoluteSelector;
}

export interface IncConsumableSlotSetDetail {
    type: IncEventType.ConsumableSlotSet;
    subject: Selector;
    slot: number;
    /**
     * `using` may be undefined as a slot may be explicitly
     * emptied.
     */
    using: CommodityKey | undefined;
}

export type IncDetail =
    IncSelectDetail |
    IncIntentChangeDetail |
    IncQuestStartDetail |
    IncConsumableSlotSetDetail;

// IncEvent(short for IncrementalEvent) is a CustomEvent that we maintain
// in order to have strong typing of UI events across components.
export interface IncEvent extends
    CustomEvent<IncDetail> {
    readonly type: IncEventType;
    bubbles: true,
    composed: true,
}

// NewIncEvent returns a IncEvent that indicate an interaction
// with a specific Character.
// 
// The specific event fired is based off of the type of detail provided.
export function NewIncEvent(detail: IncDetail): IncEvent {

    // TODO: is there a way to do this without the type assertion
    // since we cannot guarantee the detail.type is passed through
    // since CustomEvent returns a string :|
    return new CustomEvent(detail.type,
        {
            detail,
            bubbles: true,
            composed: true,
        }) as IncEvent;
}

// TODO: factor out into separate file
export class Status extends LitElement {

    @property()
    private ts = 0;

    @property({ type: Object })
    private target: Selector | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``
        }
        return html`
<div @click="${this.selected}">
    <div>${this.name()}</div>
    <div id="message">${this.message()}</div>
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            return;
        }
    }

    private selected() {
        const char = GetCollection(vcs.get().characters,
            this.target || NonMatching);
        if (!char) {
            log.debug('failed to find ', this.target);
            return;
        }
        const event = NewIncEvent({
            type: IncEventType.SelectCharacter,
            abs: NewAbsoluteSelector(char),
        })
        this.dispatchEvent(event);
    }

    private name(): string {
        if (!this.target) {
            return '';
        }

        const char = GetCollection(vcs.get().characters, this.target);
        if (!char || !char.intent) {
            throw Error(`unknown target '${JSON.stringify(this.target)}'`)
        }

        return char.id;
    }

    private message(): string {
        if (!this.target) {
            return '';
        }

        const char = GetCollection(vcs.get().characters, this.target);
        if (!char || !char.intent) {
            throw Error(`unknown target '${JSON.stringify(this.target)}'`)
        }

        // If it can be dead and is dead, we should mark it as such.
        if (char.combat && char.combat.health.current === 0) {
            return 'Dead';
        }

        // TODO: get rid of this debugging hack, its just
        // here for convenience, we need a better healthbar
        // in the Intent detail view.
        let health = '';
        if (char.combat) {
            health = String(char.combat.health.current)
        }

        switch (char.intent.Type) {
            case IntentType.FarmingMob:
                // TODO include mob name rather than opaque ID...
                return `(at ${health}) Farming ${char.intent.Aim.id}`
            case IntentType.Standby:
                return `Waiting Around`
            case IntentType.Retaliating:
                return `(at ${health}) Retaliating against ${char.intent.Proc.id}`
            default:
                return 'Unknown'
        }
    }
}
customElements.define('character-status', Status);

export class ChangeIntent extends StyleBase {

    @property()
    private ts = 0;

    @property()
    private message: string = '';

    @property({ type: Object })
    private target: Selector | undefined;

    @property({ type: IntentType })
    private to: IntentAttrs | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``
        }
        return html`
<button @click="${this.onClick}">
    ${this.message} ${this.ts.toFixed(0)}
</button>
        `;
    }

    private onClick() {
        if (this.to === undefined) {
            throw Error('cannot set intent with undefined to')
        }
        if (this.target === undefined) {
            throw Error('cannot set intent with undefined target')
        }

        const event = NewIncEvent({
            type: IncEventType.IntentChange,
            target: this.target,
            intent: this.to,
        })
        this.dispatchEvent(event);

        // const char = GetCollection(vcs.get().characters,
        //     this.target || NonMatching);
        // if (!char) {
        //     log.debug('failed to find ', this.target);
        //     return;
        // }
        // const event = NewIncEvent({
        //     type: IncEventType.SelectCharacter,
        //     abs: NewAbsoluteSelector(char),
        // })
        // console.log('trying to dispatch', event)
        // this.dispatchEvent(event);
    }

    private name(): string {
        if (!this.target) {
            return '';
        }

        const char = GetCollection(vcs.get().characters, this.target);
        if (!char || !char.intent) {
            throw Error(`unknown target '${JSON.stringify(this.target)}'`)
        }

        return char.id;
    }
}
customElements.define('intent-change', ChangeIntent);

// WHAT about two buttons; one for standby and another for farming mobs?
// These can just change Intent, it'll be nice and easy but demonstrate
// a lot of cool shit!
// (
// Farming will be hardcoded for one specific target; may want a
// 'bestiary'-type-thing... hmmm.
// )
// 
// also, I think I want a 'Retaliate' ResultHandler that checks if
// the target of a hit is in Standby AND has the 'Retaliates' field
// as truthy in the CombatAttrs. Then it will swap it to 'Retaliating'.
// intent; holy shit, I love this!

// Register the new element with the browser.
customElements.define('character-list', List); 