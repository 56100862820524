import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, Second, GetCollection } from 'eventful-increment';
import { StyleBase } from './style-base';
import { vcs } from '../state';

export class StandbyIntent extends StyleBase {

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``
        }
        return html`
Nothing to do...
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c) {
            throw Error(`unknown Character: ${JSON.stringify(this.target)}`)
        }
    }
}
customElements.define('standby-intent', StandbyIntent); 