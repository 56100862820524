import { property, html, css } from 'lit-element';
import { StyleBase } from './style-base';

// WithPopover allows having a main content pane with an associated
// popover element.
export class WithPopover extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

#popover-inner {
    position: relative;
}

#unselect {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;

    font-size: 2em;
    font-weight: bolder;
    text-shadow: 0px 0px 3px black;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private selected: boolean = false;

    constructor() {
        super();
    }

    public render() {
        return html`
<div id="wrapper">
    <div ?hidden="${!!this.selected}">
        <slot name="main"></slot>
    </div>
    <div id="popover" ?hidden="${!this.selected}">
        <div id="popover-inner">
            <div class="noselect" id="unselect"
                @click="${this.hideDetails}">&lt;</div>
            <slot name="popover"></slot>
        </div>
    </div>
</div>
`;
    }

    private hideDetails() {
        // Have the parent handle unselecting.
        this.dispatchEvent(new CustomEvent('unselect'));
    }
}
customElements.define('with-popover', WithPopover);