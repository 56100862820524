import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { StyleBase } from './style-base';
import { Time, ResultType, Result, HitResult, Selector, Second, Skill, Reward, Rewarded, RewardType, EffectResult, EffectType } from 'eventful-increment';
import { vcs, CauseEffect } from '../state';

import './svg-icon';
import './si-value';
import './reward-row';
import './effect-row';

const BaseDuration = 1.5;
const RewardDuration = BaseDuration * 2;

interface DatedTemplateResult {
    when: Time;
    content: TemplateResult;
}

const MaxSlots = 5;
// Provide a handy way to iterate over each slot
const MaxSlotsIter = Array.from(new Array(MaxSlots)).map((_, i) => i);

// NumberSplat is a single-use element which is associated
// with a specific hit. These should not be reused.
// 
// Note that positioning NumberSplat should be done by
// the parent element.
export class NumberSplat extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    position: relative;
    display: block;
}

#layout {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    margin-left: 2em;
    margin-right: 2em;
}

.splat {
    position: absolute;

    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-name: splat-movement;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.splat.hit {
    animation-duration: ${BaseDuration}s;
    font-size: 1.2em;
}

.splat.miss {
    animation-duration: ${BaseDuration}s;
    font-size: 0.9em;
}

.splat.reward {
    animation-duration: ${RewardDuration}s;
}

.magnitude {
    padding-right: 0.15em;
}

@keyframes splat-movement {
    from {
        opacity: 1;
        transform: translateY(-0.1em);
    }
  
    to {
        opacity: 0;
        transform: translateY(-1.5em);
    }
  }
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    @property()
    private type: ResultType | ResultType[] | undefined;

    private slots: Map<number, DatedTemplateResult> = new Map();
    private currentSlot: number = 0;

    constructor() {
        super();
    }

    public render() {
        if (!this.type) {
            throw Error(`type not defined ${this.type}`)
        }
        return html`
<div id="layout">
    ${MaxSlotsIter.map(i => html`
<div>${this.renderSlot(i)}</div>
`)}
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        const prevTS = changedProperties.get('ts') as number | undefined;
        if (!prevTS) {
            return;
        }

        // Find all the results that have happened that we expect.
        // Since we know prevTS is strictly increasing, we should
        // never find duplicate Result using this style.
        const newResults: DatedTemplateResult[] = vcs
            .resultsSince(prevTS, (r: Result) => {
                // Simple filter to ensure our type matches
                if (Array.isArray(this.type)) {
                    if (!this.type.includes(r.type)) {
                        return false;
                    }
                } else {
                    if (r.type !== this.type) {
                        return false;
                    }
                }
                // See if we actually implement a splat for this
                // type.
                switch (r.type) {
                    case ResultType.Hit:
                        return this.filterHit(r);
                    case ResultType.Reward:
                        return this.filterRewarded(r);
                    case ResultType.Effect:
                        return this.filterEffect(r);
                    default:
                        return false
                }
            })
            .map(v =>
                ({ when: v.cause.ts, content: this.renderResult(v) }));

        newResults.forEach(r => {
            this.slots.set(this.currentSlot, r)
            this.currentSlot = (this.currentSlot + 1) % MaxSlots;
        })
    }

    private renderSlot(slot: number) {
        if (slot >= MaxSlots) {
            throw Error(`received slot ${slot} which is > MaxSlots ${MaxSlots}`);
        }

        const result = this.slots.get(slot);
        if (!result) {
            // Nothing in the slot implies nothing to show
            return html``;
        }
        // Cleanup the slot if we have a stale element
        const { now } = vcs.get();
        if ((now - result.when) > Second * 5) {
            this.slots.delete(slot);
            return html``;
        }

        return result.content;
    }

    private filterHit(h: HitResult): boolean {
        if (!this.target) {
            throw Error('target must be configured to intercept HitResult')
        }
        return h.target.id === this.target.id
    }

    private filterRewarded(h: Rewarded): boolean {
        if (!this.target) {
            throw Error('target must be configured to intercept Rewarded')
        }
        return h.target.id === this.target.id
    }

    private filterEffect(h: EffectResult): boolean {
        if (!this.target) {
            throw Error('target must be configured to intercept EffectResult')
        }
        // Ensure this is the correct target
        if (h.source.id !== this.target.id) {
            return false;
        }
        // Whitelist the effects we display
        switch (h.effect.type) {
            case EffectType.Heal:
                return true;
            case EffectType.Lose:
                return true;
            default:
                return false;
        }
    }

    private renderMiss(r: Result): TemplateResult {
        return html`
<div when="${r.ts}" class="splat miss">
    <svg-icon
        .icon="${Skill.Defence}"></svg-icon>
</div>`
    }

    private renderHit(h: HitResult): TemplateResult {
        if (!h.Damage || h.Damage.type !== ResultType.Internal) {
            throw Error('received non-hit to render Hit')
        }
        const { damage } = h.Damage;
        return html`
<div when="${h.ts}" class="splat hit">
    <si-value class="magnitude"
        .value="${damage}"></si-value>
    <svg-icon
        .icon="${Skill.MeleeDamage}"></svg-icon>
</div>
`
    }

    private renderReward(r: Rewarded): TemplateResult {
        const { rewards } = r;
        return html`
<reward-set class="splat reward"
    .ts="${this.ts}"
    .rewards="${rewards}"></reward-set>
`
    }

    private renderEffect(r: EffectResult): TemplateResult {
        const { effect } = r;
        return html`
<effect-set class="splat reward"
    .ts="${this.ts}"
    .effects="${effect}"></effect-set>
`
    }

    private renderResult(c: CauseEffect): TemplateResult {
        const { effect: r } = c;

        switch (r.type) {
            case ResultType.Hit: {
                // Render non-damaging hits separately.
                if (!r.Damage || r.Damage.type !== ResultType.Internal) {
                    return this.renderMiss(r);
                }
                return this.renderHit(r)
            }
            case ResultType.Reward: {
                return this.renderReward(r)
            }
            case ResultType.Effect: {
                return this.renderEffect(r);
            }
            default:
                throw Error(`unknown ResultType ${r.type as unknown}`)
        }
    }
}
customElements.define('number-splat', NumberSplat);