import { property, html, css, TemplateResult } from 'lit-element';
import { StyleBase } from './style-base';
import { XPRequirement, xpToLevel, Requirement, RequirementType, NotUnderCooldownRequirement, HealthUnderThresholdRequirement, QuestCompleteRequirement, State, GetCollection, PrettyError, PrettyName } from 'eventful-increment';

import './svg-icon';
import './si-value';
import { ReadonlyDeep } from '../types/readonly-deep';
import { UIQuest } from './quest-choices';
import { vcs } from '../state';

function renderXP(req: ReadonlyDeep<XPRequirement>): TemplateResult {
    const { skill, xp } = req;
    return html`
<span class="prefix">Level&nbsp;</span>
<si-value class="magnitude"
    .value="${xpToLevel(xp)}"></si-value>
<svg-icon
    .icon="${skill}"></svg-icon>
`;
}

function renderQuestComplete(req: ReadonlyDeep<QuestCompleteRequirement>,
    s: State): TemplateResult {
    const { quest } = req;

    const q = GetCollection(s.quests, quest);
    if (!q) {
        throw PrettyError`unknown quest ${q}`
    }

    return html`
<span>Completed '${PrettyName(q)}'</span>
`;
}

function renderNotUnderCooldown(req: ReadonlyDeep<NotUnderCooldownRequirement>): TemplateResult {
    const { cd } = req;
    return html`
<span>Not under ${cd}</span>
<svg-icon
    .icon="${req.type}"></svg-icon>
`;
}

function renderHealthUnderThreshold(req: ReadonlyDeep<HealthUnderThresholdRequirement>): TemplateResult {
    const { fraction } = req;
    return html`
<span>Less than&nbsp;</span>
<si-value class="magnitude"
    .value="${fraction * 100}"></si-value>
<span>%</span>
<svg-icon
    .icon="${req.type}"></svg-icon>
`;
}

/**
 * RequirementSet displays Requirements in a standardized format
 * 
 * Note that RequirementSet is left-aligned rather than centered.
 */
export class RequirementSet extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    position: relative;
    display: block;
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
}

.magnitude {
    padding-right: 0.1em;
}

.effect {
    display: flex;
    align-items: center;
    padding-right: 0.4em;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private requirements?: Array<ReadonlyDeep<Requirement>>;

    public render() {
        if (!this.ts || !this.requirements) {
            return html``
        }
        return html`
<div>
    ${this.requirements.map(req => this.renderRequirement(req))}
</div>`;
    }

    private renderRequirement(req: ReadonlyDeep<Requirement>): TemplateResult {
        const s = vcs.get();
        let line: TemplateResult;
        switch (req.type) {
            case RequirementType.Experience:
                line = renderXP(req);
                break;
            case RequirementType.QuestComplete:
                line = renderQuestComplete(req, s);
                break;
            case RequirementType.NotUnderCooldown:
                line = renderNotUnderCooldown(req);
                break;
            case RequirementType.HealthUnderThreshold:
                line = renderHealthUnderThreshold(req);
                break;
            default:
                // Unknown requirements are fine to not show; these
                // are not a critical error so we won't throw.
                return html``;
        }
        return html`
<div class="row">
    <div class="effect">${line}</div>
</div>
`;
    }
}

customElements.define('requirement-set', RequirementSet);