// Import the LitElement base class and html helper function
import { html, property, css } from 'lit-element';

import { GetCollection, NewSelector, PayloadType, Time, Second, IntentChangePayload, Selector, QuestStartPayload, RequestDecision, ConsumableSlotSetPayload } from 'eventful-increment';
import { vcs, DBManager, League, InitLeague, handle, speed } from '../state';

import { IncEvent, IncEventType } from './character-list';
import { StyleBase } from './style-base';

import log from '../logger'

import './character-health';
import './character-intent';

import './character-choices';
import './event-log';

import './si-value';
import './svg-icon';
import './skill-level';
import './skill-list';

import './initial-onboard';
import './steady-state';

import './gather-card';
import { BannerFlavor, DismissEventDetail } from './message-banner';

import './effect-row';
import './requirement-row';
import './effect-item';
import './loadout-list';

// Extend the LitElement base class
export class Incremental extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}

#error {
    position: absolute;
    z-index: 99;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private executing = false;

    @property()
    private onboarding: boolean = true;

    private db: DBManager;

    private switchingLeagues = false;

    private errors: string[] = [];

    // selected is the Character that should be shown
    // as active on the UI across all elements.
    // 
    // The initial value here explicitly matches nothing.
    private selected: Selector | undefined = undefined;

    constructor() {
        super()

        this.db = new DBManager(
            (err) => this.onError(`DB: ${err}`),
        );

        this.selected = undefined;

        this.boot();
    }

    public render() {
        // We completely clear all element-level
        // state when switching leagues to avoid any
        // leftover references sticking around in the DOM.
        if (this.switchingLeagues) {
            return html``;
        }
        return html`
<div id="error">
    ${this.errors.map(err => html`
<message-banner
    .ts="${this.ts}"
    .flavor="${BannerFlavor.Error}"
    .message="${err}"
    @dismiss="${this.clearError}"></message-banner>
    `)}
</div>

${
            // We display onboarding information only
            // when we need to.
            // 
            // Normally, we'd use `hidden` here but don't do that
            // as it may have negative performance consequences;
            // instead, we just drop the element after we're done.
            this.onboarding ?
                html`
<initial-onboard
    .ts="${this.ts}"
    .target="${this.selected}"
    .selected="${this.selected}"
    @quest-start="${this.questStart}"
    @select-character="${this.selectCharacter}"
    @onboard-complete="${() => this.onboarding = false}"
></initial-onboard>
    ` :
                undefined
            }

<steady-state ?hidden="${this.onboarding}"
    .ts="${this.ts}"
    .selected="${this.selected}"
    @intent-change="${this.intentChange}"
    @quest-start="${this.questStart}"
    @select-character="${this.selectCharacter}"
    @consume-slot-set="${this.slotSet}"
></steady-state>
`;
    }

    public onError(err: string) {
        this.errors.push(err)
        this.errors = this.errors.slice();
    }

    private boot() {
        if (!this.db) {
            this.onError('Booting without DB')
            throw Error('cannot boot without DB')
        }

        // TODO: allow different leagues
        this.switchingLeagues = true;
        this.ts = 0;
        this.requestUpdate();

        this.stop();

        InitLeague('some-seed',
            League.Alpha, this.db,
            (msg) => this.onError(`Init: ${msg}`))
            .then(_ => {
                // Force league switch to be recognized
                // and give it time to drop and re-render
                // DOM nodes.
                setTimeout(() => {
                    this.switchingLeagues = false;
                    this.ts = 0;
                    this.start();
                }, 100);
            });
    }

    private clearError(e: CustomEvent) {
        const { detail } = e;
        const deets = detail as DismissEventDetail;
        this.errors = this.errors
            .filter(err => err !== deets.message)
    }


    private stop() {
        this.executing = false;
        handle.stop();
    }

    private start() {
        this.executing = true;
        handle.start((now) => this.tsUpdate(now));
    }

    private intentChange(e: IncEvent) {
        const d = e.detail;
        if (d.type !== IncEventType.IntentChange) {
            throw Error(`cannot change intent with ${d.type}`)
        }

        const payload: IntentChangePayload = {
            type: PayloadType.IntentChange,
            intent: d.intent,
            target: d.target,
        }
        vcs.enqueueImmediate(payload);

        const s = vcs.get();
        const char = GetCollection(s.characters, payload.target);
        if (!char) {
            log.warn(`failed to find character for requested intent change: ${JSON.stringify(payload.target)}`);
            return;
        }

        // Ensure that we're scheduled to act.
        RequestDecision(vcs.get(), NewSelector(char))
    }

    private selectCharacter(e: IncEvent) {
        const d = e.detail;
        if (d.type !== IncEventType.SelectCharacter) {
            throw Error(`cannot change intent with ${d.type}`)
        }

        this.selected = d.abs
    }

    private questStart(e: IncEvent) {
        const d = e.detail;
        if (d.type !== IncEventType.QuestStart) {
            throw Error(`cannot start quest with ${d.type}`);
        }

        const payload: QuestStartPayload = {
            type: PayloadType.QuestStart,
            target: d.subject,
            quest: d.quest,
        }
        vcs.enqueueImmediate(payload);

        // Ensure that Entity will act on this input.
        const s = vcs.get()
        const subject = GetCollection(s.characters, d.subject);
        if (!subject) {
            log.debug(`missing subject: ${JSON.stringify(d.subject)}`)
            return;
        }
        RequestDecision(vcs.get(), NewSelector(subject));
    }

    private slotSet(e: IncEvent) {
        const d = e.detail;
        if (d.type !== IncEventType.ConsumableSlotSet) {
            throw Error(`cannot choose slot with ${d.type}`);
        }

        const { slot, using } = d;
        const payload: ConsumableSlotSetPayload = {
            type: PayloadType.ConsumableSlotSet,
            target: d.subject,
            slot, using,
        }
        vcs.enqueueImmediate(payload);
    }

    private tsUpdate(now: Time) {
        // There's no need to update our timestamp as hard realtime;
        // limiting our timestamp allows for a large performance
        // gain with little overhead.
        if (now - this.ts < (speed * Second / 20)) {
            return;
        }
        this.ts = now;

        // Make the linter not complain and also have a guard
        // for unusual behavior
        if (!this.ts && this.ts !== 0) {
            throw Error('ts not positive')
        }
    }
}

// Register the new element with the browser.
customElements.define('incremental-fe', Incremental);