import { property, html, css, TemplateResult } from 'lit-element';
import { StyleBase } from './style-base';
import { EffectType, Effect } from 'eventful-increment';

import { ReadonlyDeep } from '../types/readonly-deep';

import './effect-item';

/**
 * EffectSet displays Effects in a standardized formar
 */
export class EffectSet extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    position: relative;
    display: block;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private effects?: Array<ReadonlyDeep<Effect>> | ReadonlyDeep<Effect>;

    public render() {
        if (!this.ts || !this.effects) {
            return html``
        }
        let effects = Array.isArray(this.effects) ?
            this.effects : [this.effects];
        // Prevent displaying losses since they will overlap
        effects = effects.filter(e => e.type !== EffectType.Lose)
        return html`
<div>
    ${effects.map(effect => html`
<effect-item .effect="${effect}"></effect-item>
`)}
</div>`;
    }
}

customElements.define('effect-set', EffectSet);