import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { StyleBase } from './style-base';
import { ResultType, Result, HitResult, Second, State, GetCollection, PrettyName, Event, PayloadType, HitPayload, SelectorAsAbsolute, EffectResult } from 'eventful-increment';
import { vcs, CauseEffect } from '../state';

import './outline-box';

import './effect-item';

const WhitelistedTypes = [
    // TODO: probably render all these.
    // ResultType.Reap,
    // ResultType.Retaliate,
    // ResultType.Respawn,
    ResultType.Hit,
    ResultType.Effect,
];

function renderHit(s: State, e: Event, h: HitResult): TemplateResult {
    // Don't render impossible hits
    if (h.MayHit.type !== ResultType.Internal) {
        return html``
    }

    if (!e.payload || e.payload.type !== PayloadType.Hit) {
        throw Error(`non-Hit payload present on event: ${JSON.stringify(e)}`);
    }
    const payload = e.payload as HitPayload;

    // TODO: is this going to fail because target might not
    // be an absolute selector?
    const target = GetCollection(s.characters,
        SelectorAsAbsolute(h.target));
    if (!target) {
        throw Error(`cannot find target ${JSON.stringify(h.target)}`)
    }
    const source = GetCollection(s.characters,
        SelectorAsAbsolute(payload.source));
    if (!source) {
        throw Error(`cannot find target ${JSON.stringify(source)}`)
    }

    if (!h.DoesHit || h.DoesHit.type === ResultType.NoCombat) {
        throw Error(`invalid DoesHit on successful MayHit: ${JSON.stringify(h)}`)
    }
    if (!h.DoesHit.hit) {
        return html`${PrettyName(source)} missed ${PrettyName(target)}`
    }

    if (!h.Damage || h.Damage.type !== ResultType.Internal) {
        throw Error(`invalid damage on successful DoesHit: ${JSON.stringify(h)}`)
    }
    return html`${PrettyName(source)} hit ${PrettyName(target)} for ${h.Damage.damage}`
}

function renderEffect(s: State, e: Event, effect: EffectResult): TemplateResult {
    const source = GetCollection(s.characters,
        SelectorAsAbsolute(e.subject));
    if (!source) {
        throw Error(`cannot find target ${JSON.stringify(source)}`)
    }

    return html`
${PrettyName(source)}&nbsp; <effect-item .effect="${effect.effect}"></effect-item>
`
}

export class EventLog extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            // Ensure our content stays at a fixed height.
            // 
            // We set an explicit height rather than max-height to avoid random
            // height changes when we have fewer elements than expected.
            css`
#wrapper {
    height: 10em;
    overflow-x: hidden;
}

.line {
    display: flex;
    flex-direction: row;
    align-items: center;
}
`
        ];
    }

    @property()
    private ts = 0;

    private recentResults: CauseEffect[] = [];

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``;
        }
        return html`
<outline-box>
    <div id="wrapper">
    ${this.recentResults
                .map(w => this.renderResult(w))
                .map(e => html`<p>${e}</p>`).reverse()}
    </div>
</outline-box>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        const prevTS = changedProperties.get('ts') as number | undefined;
        if (!prevTS) {
            return;
        }

        // Fetch the current time out of the state.
        const { now } = vcs.get();
        // Filter out results that have completed to prune elements that
        // should have stopped animating already.
        this.recentResults = this.recentResults.filter(v =>
            (now - v.effect.ts) < Second * 2);
        // Find all the results that have happened that we expect.
        // Since we know prevTS is strictly increasing, we should
        // never find duplicate Result using this style.
        const newResults: CauseEffect[] = vcs
            .resultsSince(prevTS, (r: Result) =>
                WhitelistedTypes.includes(r.type));
        this.recentResults.push(...newResults);
    }

    private renderResult(c: CauseEffect): TemplateResult {
        const { effect: r, cause } = c;

        const s = vcs.get();
        let line: TemplateResult;
        switch (r.type) {
            case ResultType.Hit:
                line = renderHit(s, cause, r)
                break;
            case ResultType.Effect:
                line = renderEffect(s, cause, r)
                break;
            default:
                // Since we whitelist the ResultTypes we handle, this
                // encountering an unexpected Result means an invariant
                // has been broken.
                throw Error(`unknown ResultType ${r.type as unknown}`)
        }

        return html`
<div class="line">${line}</div>
`
    }
}
customElements.define('event-log', EventLog);