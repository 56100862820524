import './svg-icon';
import './si-value';
import { ReadonlyDeep } from '../types/readonly-deep';
import { HealEffect, EffectType, CooldownEffect, Second, Effect, LoseEffect } from 'eventful-increment';
import { TemplateResult, html } from 'lit-html';
import { StyleBase } from './style-base';
import { css, property } from 'lit-element';

function renderHeal(effect: ReadonlyDeep<HealEffect>): TemplateResult {
    const { amount } = effect;
    return html`
<si-value class="magnitude"
    .value="${amount}"></si-value>
<svg-icon
    .gain="${true}"
    .icon="${EffectType.Heal}"></svg-icon>
`;
}

function renderCooldown(effect: ReadonlyDeep<CooldownEffect>,
    short: boolean): TemplateResult {
    const { duration } = effect;
    // We add a 's' suffix here since its an easy way to normalize durations
    return html`
<si-value class="magnitude"
    .value="${duration / Second}"></si-value>
<span>s</span>
${short ? undefined : html`<span>&nbsp;${effect.cd}</span>`}
<svg-icon
    .gain="${true}"
    .icon="${EffectType.Cooldown}"></svg-icon>
`;
}

function renderLoss(effect: ReadonlyDeep<LoseEffect>): TemplateResult {
    const { key, amount } = effect;
    // We add a 's' suffix here since its an easy way to normalize durations
    return html`
<span>-${amount} ${key}</span>
<svg-icon
    .gain="${true}"
    .icon="${key}"></svg-icon>
`;
}

export class EffectItem extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
}
`
        ];
    }

    @property()
    private effect?: ReadonlyDeep<Effect>;

    @property()
    private short?: boolean;

    public render() {
        if (!this.effect) {
            return html``
        }
        const effect = this.effect;

        let line: TemplateResult;
        switch (effect.type) {
            case EffectType.Cooldown:
                line = renderCooldown(effect, !!this.short);
                break;
            case EffectType.Heal:
                line = renderHeal(effect);
                break;
            case EffectType.Lose:
                line = renderLoss(effect);
                break;
            default:
                // Unknown effects are fine to not show; these
                // are not a critical error so we won't throw.
                return html``;
        }

        return html`
<div class="row">
    ${line}
</div>`;
    }
}

customElements.define('effect-item', EffectItem);