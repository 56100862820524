import { html } from 'lit-html';
import { StyleBase } from './style-base';
import { css, property } from 'lit-element';
import { Selector, Label } from 'eventful-increment';

import './character-list';
import './skill-list';
import './character-choices';
import './event-log';

export class SteadyState extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}
`
        ];
    }

    @property()
    private ts = 0;

    // the caller of this element is expected to handle selected
    @property()
    private selected: Selector | undefined;

    public render() {
        if (!this.ts) {
            return html``
        }
        return html`
<character-list
    .ts="${this.ts}"
    .selected="${this.selected}"
    .filter="${Label.Player}"></character-list>
<skill-list
    .ts="${this.ts}"
    .target="${this.selected}"
    ></skill-list>
<character-choices
    .ts="${this.ts}"
    .target="${this.selected}""></character-choices>
<event-log .ts=${this.ts}></event-log>
`;
    }
}
customElements.define('steady-state', SteadyState);