import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, Second, GetCollection, IntentType, PrettyName } from 'eventful-increment';
// tslint:disable-next-line: no-submodule-imports
import { styleMap, StyleInfo } from 'lit-html/directives/style-map.js';
import { StyleBase } from './style-base';
import { vcs } from '../state';

import './outline-box'
import './standby-intent'
import './farm-intent'
import './specific-targeted-intent'
import './gathering-intent'

export class CharacterIntent extends StyleBase {

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    private intent: IntentType | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``
        }
        return html`
<outline-box>
    <div>
        ${this.subchild}
    </div>
</outline-box>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c || !c.intent) {
            throw Error(`unknown character or without intent: ${JSON.stringify(this.target)}`)
        }
        this.intent = c.intent.Type;
    }

    private get subchild(): TemplateResult {
        switch (this.intent) {
            case IntentType.Standby:
                return html`<standby-intent .ts="${this.ts}" .target="${this.target}"></standby-intent>`
            case IntentType.FarmingMob:
                return html`<farm-intent .ts="${this.ts}" .target="${this.target}"></farm-intent>`
            case IntentType.SpecificTargeted:
                return html`<specific-targeted-intent .ts="${this.ts}" .target="${this.target}"></specific-targeted-intent>`
            case IntentType.Gathering:
                return html`<gathering-intent .ts="${this.ts}" .target="${this.target}"></gathering-intent>`
            default: {
                return html`unknown intent`;
            }
        }
    }
}
customElements.define('character-intent', CharacterIntent); 