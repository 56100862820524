import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, Second, GetCollection, IntentType, PrettyName } from 'eventful-increment';
// tslint:disable-next-line: no-submodule-imports
import { styleMap, StyleInfo } from 'lit-html/directives/style-map.js';
import { StyleBase } from './style-base';
import { vcs } from '../state';

import './character-health'

export class FarmIntent extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
#us {
    grid-area: us;
}

#them {
    grid-area: them;
}

#split {
   display: grid;
   grid-template-columns: 45fr 10fr 45fr;
   grid-template-rows: 100fr;
   grid-gap: 2px;
   grid-template-areas:
     "us       .      them";
   justify-self: center;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;
    private enemy: Selector | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts || !this.enemy) {
            return html``
        }
        return html`
<div id="split">
    <div id="us">
        <character-health .ts="${this.ts}" .target="${this.target}"></character-health>
    </div>
    <div id="them">
        <character-health .ts="${this.ts}" .target="${this.enemy}"></character-health>
    </div>
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c || !c.intent) {
            throw Error(`unknown Character or intent: ${JSON.stringify(this.target)}`)
        }

        // If we're not farming, we don't have an enemy
        // 
        // This is usually to cover the situation where we are
        // transitioning to another Intent but this is still being
        // displayed.
        if (c.intent.Type !== IntentType.FarmingMob) {
            this.enemy = undefined;
            return;
        }

        this.enemy = c.intent.Aim;
    }
}
customElements.define('farm-intent', FarmIntent);

// OKAY: so, a couple things to do here!
// We probably want to use css grid, or something to put healthbars
// in the middle and beside each other.
// Then, hitsplats and xp can go above them as 'floaty things'.
// Below them, we can have loot/ability-bars(uh, yeah; later)
// 
// We also need a way to surface Results. Right now, they're just
// discarded. I feel like we need a way to surface these.
// My initial idea is to get the 'previous ts'(available in 'update')
// then run that through vcs.after(), which will be a new function that
// returns Result that happened after a specific time.
// This will require vcs to retain them in a 'lookback' buffer
// which will let us track Results that occurred within a specific
// window(probably a small subset of the iteration time). Windowing
// is hard to do right but we can probably have a very simple solution
// out fairly quickly.