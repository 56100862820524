import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, Second, GetCollection, IntentType, PrettyName, IntentAttrs, AvailableQuests, NewAbsoluteSelector, AbsoluteSelector, NonMatching, StepType, FightStep, State, QuestStep, GetSkillLevel, Skill, Character, NewLivingSelector, xpToLevel, ResourceNode, gatherThreshold, } from 'eventful-increment';
import { StyleBase } from './style-base';
import { vcs } from '../state';
import { NewIncEvent, IncQuestStartDetail, IncEventType, IncIntentChangeDetail } from './character-list';
import { UICharacter } from './farm-choices';
import { CombatShorthand } from './quest-card';
import { ReadonlyDeep } from '../types/readonly-deep';

export type UIResourceNode = ReadonlyDeep<ResourceNode>;

import './reward-row';

export function gatherSuccessPercent(s: State,
    target: Selector, node: UIResourceNode): number {

    const char = GetCollection(s.characters, target);
    if (!char) {
        throw Error(`unknown Character ${JSON.stringify(target)}`);
    }
    const { skill, difficulty } = node.resources;
    const level = GetSkillLevel(char.skills, skill);
    let chance = Math.floor(gatherThreshold(difficulty, level) * 100)
    if (chance > 100) {
        chance = 100;
    }
    return chance;
}

export class GatherCard extends StyleBase {
    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}

ol {
    margin: 0px;
}

.card-header {
    display: flex;
    align-items: center;
}

.card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#skill-name {
    font-size: 0.75em;
}

#header-stats {
    margin-left: auto;
}

#gutter {
    display: flex;
    align-items: center;
}

#description {
    margin-right: 1em;
}

#buttons {
    margin-left: auto;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    @property()
    private subject: AbsoluteSelector | undefined;

    // TODO: we need to handle active character :|
    @property()
    private activeNode: Selector | undefined;

    private details: UIResourceNode | undefined;
    private percentChance: number = 0;
    private percentAvailable: number = 0;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts || !this.details) {
            return html``
        }
        // Snapshot non-undefined state for use in template
        const details = this.details;
        const name = PrettyName(details);
        let description = '';
        if (details.display) {
            description = details.display.description;
        }

        const { difficulty, skill, } = details.resources;
        return html`
<div class="card">
    <div class="card-header">
        <div>Node</div>
        <div id="header-stats">
            Difficulty ${difficulty} (${this.percentChance}%)
        </div>
    </div>
    <div class="card-body">
        <h4 class="card-title">
            <div>${name}</div>
            <div id="skill-name">${skill}</div>
        </h4>
        <h5 class="card-subtitle"></h5>
        <p class="card-text">${this.textSection()}</p>
        <div id="gutter">
            <div id="description"> 
                <div>${description}</div>
                <div>${this.percentAvailable}% Remaining</div>
            </div>
            <div id="buttons">${this.performButton(details)}</div>
        </div>
    </div>
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        // It is valid for us not to have a target
        // so we handle that appropriately by removing the card.
        if (!this.target || !this.subject) {
            this.details = undefined;
            return;
        }

        const s = vcs.get();
        const node = GetCollection(s.nodes, this.subject);
        this.details = node;
        // Don't bother trying to do calculations without a node.
        if (!node) {
            // Reset percent chance.
            this.percentChance = 0;
            return;
        }

        this.percentChance = gatherSuccessPercent(s, this.target, node);

        const { current, max } = node.resources;
        let availablePercent = Math.floor((current / max) * 100);
        if (availablePercent > 100) {
            availablePercent = 100;
        }
        this.percentAvailable = availablePercent;

        // TODO: include percentAvailable in the table!
    }

    private performButton(details: UIResourceNode): TemplateResult {
        // TODO: how to ensure this updates correctly;
        // I don't think we want activeChar
        if (this.activeNode && details.id === this.activeNode.id) {
            return html`
<div>
Gathering
</div>`
        }
        return html`
<button
    @click="${() => this.startGathering(details)}">
Gather
</button>
`
    }

    private startGathering(c: UIResourceNode) {
        if (!this.target) {
            throw Error('cannot gather with with invalid target')
        }
        const detail: IncIntentChangeDetail = {
            type: IncEventType.IntentChange,
            target: this.target,
            intent: {
                Type: IntentType.Gathering,
                Aim: NewAbsoluteSelector(c),
            }
        }
        this.dispatchEvent(NewIncEvent(detail));
    }

    private textSection(): TemplateResult {
        if (!this.details || !this.details.reward) {
            return html``;
        }
        const { rewards } = this.details.reward;

        return html`
<div class="collapsible">
    <input id="collapsible1" type="checkbox" name="collapsible" checked>
    <label for="collapsible1">Rewards</label>
    <div class="collapsible-body">
        <reward-set
            .ts="${this.ts}"
            .rewards="${rewards}"></reward-set>
    </div>
</div>
`
    }
}
customElements.define('gather-card', GatherCard);