import { PropertyValues, html, TemplateResult, property } from 'lit-element';
import { GetCollection, IntentType, PrettyName, NewAbsoluteSelector, AbsoluteSelector, Character, CharactersWithLabel, Label, RewardXPSum, CheckRequirementsGroup, Selector, ResourceNode, EntitiesWithLabel, GetSkillLevel } from 'eventful-increment';
import { vcs } from '../state';

import './si-value';
import { ReadonlyDeep } from '../types/readonly-deep';
import { InternalClass } from './outline-box';

// tslint:disable-next-line:no-submodule-imports
import { classMap } from 'lit-html/directives/class-map';

import { ChoiceList, Sortable, Sorting } from './choice-list';
import { UIResourceNode, gatherSuccessPercent } from './gather-card';

// UICharacter is a Character that can be used for display
// purposes. It is immutable. This reference should be
// updated whenever this card changes.
export type UICharacter = ReadonlyDeep<Character>;

/**
 * Sort is used by table headers for use-facing display
 * as well as our internal sort reference.
 * 
 * By tying these together, things get easier.
 */
enum Sort {
    Success = 'Success',
    Experience = 'Exp',
}

function bySuccess(a: UIResourceNode, b: UIResourceNode): number {
    return a.resources.difficulty - b.resources.difficulty
}

function byExperience(a: UIResourceNode, b: UIResourceNode): number {
    // Note that this is extremely slow; we should be careful not to
    // sort very long lists like this.
    // TODO: consider increasing performance here.
    return RewardXPSum(a as ResourceNode) - RewardXPSum(b as ResourceNode)
}

function sortNodes(s: Sort | string, ascending: boolean,
    nodes: UIResourceNode[]) {
    nodes.sort((a, b) => {
        switch (s) {
            case Sort.Success:
                return bySuccess(a, b);
            case Sort.Experience:
                return byExperience(a, b);
            default:
                throw Error(`unknown MobSort: ${JSON.stringify(s)}`);
        }
    });

    if (!ascending) {
        nodes.reverse()
    }
}

export class GatherChoices extends ChoiceList {
    private nodes: UIResourceNode[] = [];

    @property()
    private activeNode: Selector | undefined;

    constructor() {
        super();
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const targetChange = changedProperties.get('target');
        if (targetChange) {
            this.activeNode = undefined;
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);

        if (!c || !c.intent) {
            // No intent means we can't do anything with this Character
            this.nodes = [];
            return;
        }

        if (c.intent.Type === IntentType.Gathering) {
            this.activeNode = c.intent.Aim
        }

        // Only do the expensive work of filtering if
        // we NEED to or if we haven't done so yet.
        if (!!changedProperties.get('by') ||
            this.nodes.length === 0) {

            this.nodes = EntitiesWithLabel(s.nodes, Label.Farmable)
                .map(sel => GetCollection(s.nodes, sel) as UIResourceNode);

            // Apply our sorting semantics
            if (!this.by.name) {
                return;
            }
            sortNodes(this.by.name, this.by.ascending, this.nodes);
            this.nodes = this.nodes.slice();
        }

    }

    protected defaultSort(): Sorting {
        return {
            name: Sort.Experience,
            ascending: true,
        }
    }

    protected header(): Sortable[] {
        return [
            {
                name: Sort.Success,
                sortBy: () => true,
            },
            {
                name: Sort.Experience,
                sortBy: () => true,
            },
            {
                name: 'Status',
                sortBy: () => false,
            }
        ];
    }

    protected row(index: number): TemplateResult | undefined {
        // Ensure bounds are valid
        if (index >= this.nodes.length) {
            return undefined;
        }
        if (!this.target) {
            return undefined;
        }

        const node = this.nodes[index];
        const name = PrettyName(node);

        // We have to cast here due to the readonly constraint
        // we work under. This is unpalatable but there is no easy
        // way around it.
        const totalXP = RewardXPSum(node as ResourceNode);

        let farmingString = '';
        if (this.activeNode && node.id === this.activeNode.id) {
            farmingString = 'Gathering';
        }

        const s = vcs.get();

        const targetPlayer = GetCollection(s.characters, this.target);
        if (!targetPlayer) {
            throw Error(`unknown Character: ${JSON.stringify(this.target)}`);
        }
        const chance = gatherSuccessPercent(s, this.target, node);

        const players: Character[] = CharactersWithLabel(s, Label.Player)
            .map(c => GetCollection(s.characters, c) as Character);

        // There is an issue here where we're going to allow Characters
        // who shouldn't be able to fight being allowed to see this :|
        const unmetRequirements = CheckRequirementsGroup(s,
            node as ResourceNode, ...players);
        const disabled = unmetRequirements.length > 0;
        const classes: InternalClass = {
            'disabled': disabled,
            // Shake on desktop hover or mobile tap;
            // this is provided by our customer paper.css
            // Tap behavior is a little funky but gets the job done.
            'shake-hover': disabled,
        };

        return html`
        <tr
            @click="${() => disabled ? null : this.selected = NewAbsoluteSelector(node)}"
            class=${classMap(classes)}>
            <td>${name}</td>
            <td>${chance}%</td>
            <td>
                <si-value .value="${totalXP}"></si-value>xp
            </td>
            <td>${farmingString}</td>
        </tr>
        `
    }

    protected card(sel: AbsoluteSelector | undefined): TemplateResult {
        return html`
<gather-card class="mob"
    .ts="${this.ts}"
    .target="${this.target}"
    .subject="${sel}"
    .activeNode="${this.activeNode}"></gather-card>
`
    }
}
customElements.define('gather-choices', GatherChoices);