import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { StyleBase } from './style-base';
import { Time, ResultType, Result, HitResult, Selector, Second, State, GetCollection, PrettyName, Event, PayloadType, HitPayload, SelectorAsAbsolute } from 'eventful-increment';
import { vcs, CauseEffect } from '../state';

import './outline-box';

export enum BannerFlavor {
    Error = 'error',
}

export const DismissEvent: string = 'dismiss';

export interface DismissEventDetail {
    message: string;
}

export class MessageBanner extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}

#wrapper {
    display: flex;
    flex-direction: row;
}

#wrapper[flavor="error"] {
    background-color: #D50000;
    color: #FFCDD2;
}

#message {
    padding: 0.7em;
    font-size: 1.3em;
}

button {
    margin: unset;
    margin-left: auto;
    margin-right: 1em;

    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private flavor: BannerFlavor | undefined;

    private message: string = '';

    constructor() {
        super();
    }

    public render() {
        if (!this.ts || !this.message || !this.flavor) {
            return html``;
        }
        return html`
<div id="wrapper" flavor="${this.flavor}">
    <div id="message">Error: ${this.message}</div>
    <button id="dismiss" @click="${this.dismiss}">Dismiss</button>
</div>
`;
    }

    private dismiss() {
        const detail: DismissEventDetail = {
            message: this.message,
        }
        const e = new CustomEvent(DismissEvent,
            {
                detail,
                bubbles: true,
                composed: true,
            });
        this.dispatchEvent(e);
    }
}
customElements.define('message-banner', MessageBanner);