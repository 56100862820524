import { StyleBase } from './style-base';
import { html } from 'lit-html';
import { property, css } from 'lit-element';
// tslint:disable-next-line:no-submodule-imports
import { classMap, ClassInfo } from 'lit-html/directives/class-map.js';

// These are based off of the papercss border classes.
const BorderMin = 0;
const BorderMax = 7;

// InternalClass is a writeable subset of lit-html's ClassInfo
export interface InternalClass {
    [name: string]: string | boolean | number;
}

export class OutlineBox extends StyleBase {

    static get styles() {
        // Add a wee bit of padding, otherwise outline-box
        // tends to get jammed together.
        return [
            ...super.styles,
            css`
#wrapper {
    padding: 0.2em;
}
`
        ];
    }

    @property()
    private focused = false;

    private borderVariant: string = '';

    constructor() {
        super();

        const rand = Math.random();
        const variant = Math.floor(rand * (BorderMax + BorderMin) - BorderMin);
        if (variant > 0) {
            this.borderVariant = `border-${variant}`;
        }
    }

    public render() {
        // TODO: should we avoid having a wrapper just
        // to ensure that we have padding?
        return html`
<div id="wrapper">
    <div class=${classMap(this.classes)}>
        <slot></slot>
    </div>
</div>
`;
    }

    public get classes(): ClassInfo {
        const classes: InternalClass = {
            'border': true,
            'col': true,
            'border-primary': true,

            'shadow': this.focused,
            'border-dashed': this.focused,
        };
        if (this.borderVariant.length > 0) {
            classes[this.borderVariant] = true;
        }

        return classes
    }
}
customElements.define('outline-box', OutlineBox);