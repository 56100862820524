import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { StyleBase } from './style-base';
import { Time, ResultType, Result, HitResult, Selector, Second, Skill, Reward, Rewarded, RewardType, GetCollection, GetSkillInfo, xpToLevel, levelToXP, SkillInfo } from 'eventful-increment';
import { vcs, CauseEffect } from '../state';

import log from '../logger'

import './skill-level';
import './outline-box';

const AllSkills: Map<Skill, void> = Object.values(Skill)
    .reduce((accum, skill) => accum.set(skill, true), new Map())

export class SkillList extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    position: relative;
}

#wrapper {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(7.5em, 1fr));
    grid-auto-rows: auto;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    private skills: Skill[] = [];

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``;
        }

        const lines = this.skills.map(skill => this.levelLine(skill));

        return html`
<outline-box>
    <div id="wrapper">
        ${!!lines.length ? lines : html`Select a character`}
    </div>
</outline-box>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            this.skills = [];
            return;
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c) {
            log.warn(`failed to find Character: ${JSON.stringify(this.target)}`);
            return;
        }

        // Acquire the names without having to iterate
        // TODO: is this coercion safe?
        this.skills = Object.keys(c.skills)
            .filter((skill: any) => AllSkills.has(skill)) as Skill[];
    }

    private levelLine(skill: Skill): TemplateResult {
        return html`
<skill-level
    .ts="${this.ts}"
    .target="${this.target}"
    .skill="${skill}"
    ></skill-level>
`
    }
}
customElements.define('skill-list', SkillList);