import { LitElement, css, CSSResult, unsafeCSS, property, html, TemplateResult, svg, SVGTemplateResult } from 'lit-element';

import { SkillsKey, CommodityKey, EffectsKey, RequirementKey } from 'eventful-increment';

// tslint:disable-next-line:no-submodule-imports
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

// Most icons are sourced from https://game-icons.net/
// With, specifically, an alpha-based background.
// Our color palette is from https://colorhunt.co/palette/138147
// Primary: #3c415e
// Secondary: #738598
// Teriary: #dfe2e2
// Emphasis: #1cb3c8 (used for representing gain)
// Emphasis-Complement: #e1341e (used for representing loss)

// Ignore raw-loader making the linter angry
// also ClassInfo + classMap make it angry...
// tslint:disable:no-submodule-imports
// tslint:disable:no-implicit-dependencies
import { ClassInfo, classMap } from 'lit-html/directives/class-map';
import { InternalClass } from './outline-box';

// Skills
import health from 'raw-loader!../resources/skills/health.svg';
import defence from 'raw-loader!../resources/skills/defence.svg';
import meleeAccuracy from 'raw-loader!../resources/skills/melee-accuracy.svg';
import meleeDamage from 'raw-loader!../resources/skills/melee-damage.svg';
import panacea from 'raw-loader!../resources/skills/panacea.svg';

// Inventory
import herb from 'raw-loader!../resources/inventory/herb.svg';

// Effects
import cooldown from 'raw-loader!../resources/effects/cooldown.svg';

// Cards
import enemy from 'raw-loader!../resources/cards/enemy.svg';
import quest from 'raw-loader!../resources/cards/quest.svg';
import gather from 'raw-loader!../resources/cards/gather.svg';
import loadout from 'raw-loader!../resources/cards/loadout.svg';

export enum Cards {
    Mob = 'Mob',
    Quest = 'Quest',
    Gather = 'Gather',
    Loadout = 'Loadout',
}

export type CardsKey = keyof typeof Cards;

type IconKey =
    CardsKey |
    CommodityKey |
    SkillsKey |
    EffectsKey |
    RequirementKey;

type IconMap = {
    [s in IconKey]?: SVGTemplateResult;
}

function icon(text: string): TemplateResult {
    return html`${unsafeHTML(text)}`
}

const healthHTML = icon(health);
const herbHTML = icon(herb);
const cooldownHMTL = icon(cooldown);
const questHTML = icon(quest);

const map: IconMap = {
    // Skills
    Health: healthHTML,
    Defence: icon(defence),
    MeleeDamage: icon(meleeDamage),
    MeleeAccuracy: icon(meleeAccuracy),
    Panacea: icon(panacea),

    // Inventory
    Basilic: herbHTML,

    // Effects
    Cooldown: cooldownHMTL,
    Heal: healthHTML,

    // Requirements
    QuestComplete: questHTML,
    NotUnderCooldown: cooldownHMTL,
    HealthUnderThreshold: healthHTML,

    // Cards
    Mob: icon(enemy),
    Quest: questHTML,
    Gather: icon(gather),
    Loadout: icon(loadout),
}

export class SVGIcon extends LitElement {

    static get styles() {
        // Styles smooth over some inconsistencies.
        // - Remove whatever background is present and replace it
        //   with full transparency.
        // - We unset inline height/width to fit the wrapper.
        return [
            css`
:host {
    position: relative;
}

svg {
    background: unset !important;
    background-color: rgba(0, 0, 0, 0.0);
    width: unset !important;
    height: unset !important;
}

.gain svg path {
    fill: #1cb3c8;
}

.loss svg path {
    fill: #e1341e;
}

#wrapper {
    display: inline-block;
    width: 1.5em;
    height: auto;
}

#wrapper[large] {
    width: 2.5em;
}
`
        ];
    }

    @property()
    private icon: IconKey | undefined;

    @property()
    private gain: boolean | undefined;

    @property()
    private loss: boolean | undefined;

    @property()
    private large: boolean | undefined;

    public render() {
        if (!this.icon) {
            return html``
        }
        return html`
<div
    class=${classMap(this.classes)}
    ?large="${!!this.large}"
    id="wrapper">${map[this.icon]}</div>`;
    }

    public get classes(): ClassInfo {
        if (this.gain && this.loss) {
            throw Error(`cannot lose and gain simultaneously`);
        }
        const classes: InternalClass = {
            'gain': !!this.gain,
            'loss': !!this.loss,
        };
        return classes
    }
}
customElements.define('svg-icon', SVGIcon);