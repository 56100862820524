import { PropertyValues, property, html, css } from 'lit-element';
import { Selector, EntitiesWithTag, FirstIntroQuestTag, AbsoluteSelector, SelectorType, Second, GetCollection, NonMatching, Result, ResultType, QuestResult, QuestStateChange, SecondIntroQuestTag, Time, Label, CharactersWithLabel, ThirdIntroQuestTag } from 'eventful-increment';
import { StyleBase } from './style-base';
import { vcs } from '../state';

import './quest-card';
import { UIQuest } from './quest-choices';
import { NewIncEvent, IncEventType } from './character-list';

const BaseFadeInDuration = 1.5;
const TopMessageFadeIn = BaseFadeInDuration;
const CharacterFadeIn = BaseFadeInDuration * 3;
const BottomMessageFadeIn = BaseFadeInDuration * 5;

enum OnboardStep {
    PriorToFirstQuest,
    FirstQuestDone,
    SecondQuestDone,
    ThirdQuestDone,
    Complete,
}

export class InitialOnboard extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}

*[noshow] {
    opacity: 0 !important;
}

#wrapper {
    display: flex;
    flex-direction: column;
}

#top-message {
    font-size: 1.5em;
    margin-left: 15%;

    animation-duration: ${TopMessageFadeIn}s;
}

#bottom-message {
    font-size: 2em;
    margin-left: auto;
    margin-right: 10%;

    animation-duration: ${BottomMessageFadeIn}s;
}

.message {
    min-height: 1.3em;
}

#initial-quest {
    margin-right: auto;
    margin-left: auto;
    animation-duration: ${BaseFadeInDuration}s;
}

.fades-in {
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-name: fade-in;
}

.fades-in[noshow] {
    /* Disable animations when we're set not to show */
    animation-name: unset;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#player-row {
    justify-self: center;
    
    max-width: 80em;
    animation-duration: ${CharacterFadeIn}s;
}
`
        ];
    }
    // TODO: remove initial sizing stuff after testing??

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    // the caller of this element is expected to handle selected
    @property()
    private selected: Selector | undefined;

    private activeQuest: Selector | undefined;

    private step: OnboardStep = OnboardStep.PriorToFirstQuest;

    private currentQuest: UIQuest | undefined;

    private quests: Map<OnboardStep, AbsoluteSelector> = new Map();

    private completed: boolean = false;

    constructor() {
        super();

        const s = vcs.get();

        const registerQuest = (step: OnboardStep, tag: string) => {
            const q = EntitiesWithTag(s.quests, tag);
            if (q.type === SelectorType.NonMatching) {
                throw Error(`failed to find quest with tag ${tag}`)
            }
            this.quests.set(step, q)
        }
        registerQuest(OnboardStep.PriorToFirstQuest, FirstIntroQuestTag)
        registerQuest(OnboardStep.FirstQuestDone, SecondIntroQuestTag)
        registerQuest(OnboardStep.SecondQuestDone, ThirdIntroQuestTag)
    }

    public connectedCallback() {
        super.connectedCallback();

        // Select a Character when we're attached to the DOM
        // so the event can actually be handled.
        if (this.selected !== undefined) {
            return;
        }

        const s = vcs.get();

        const chars = CharactersWithLabel(s, Label.Player);
        if (chars.length === 0) {
            throw Error(`at least one Character labeled ${Label.Player} must exist to onboard`)
        }

        const selEvent = NewIncEvent({
            type: IncEventType.SelectCharacter,
            abs: chars[0],
        });
        this.dispatchEvent(selEvent);
    }

    // TODO: audit all used elements to ensure they are imported!

    public render() {
        if (!this.ts) {
            return html``
        }
        return html`
<div id="wrapper">
    <div
        id="top-message" class="fades-in message"
        ?noshow="${this.step >= OnboardStep.FirstQuestDone}"
        >Ow</div>
    <div id="player-row" class="fades-in">
        <character-list
            .ts="${this.ts}"
            .selected="${this.selected}"
            .filter="${Label.Player}"></character-list>
    </div>
    <skill-list
        class="fades-in"        
        ?noshow="${this.step < OnboardStep.FirstQuestDone}"
        .ts="${this.ts}"
        .target="${this.selected}"
        ></skill-list>
    <div id="bottom-message" class="fades-in message"
        ?noshow="${this.step >= OnboardStep.FirstQuestDone}"
        >What's that sound?</div>

    <quest-card id="initial-quest"
        class="fades-in"
        .ts="${this.ts}"
        .target="${this.target}"
        .quest="${this.currentQuest}"
        .activeQuest="${this.activeQuest}"></quest-card>
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        // We can't onboard without a target...
        if (!this.target) {
            return;
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c || !c.quest) {
            throw Error(`target must exist and have quest present`);
        }

        this.setStep();

        // Signal and note we're done if we've reached
        // the final step
        if (this.step === OnboardStep.Complete &&
            !this.completed) {
            this.completed = true;

            // We use a one-off event to signal this
            // since this will only ever happen once
            this.dispatchEvent(new CustomEvent('onboard-complete'));
        }

        // TODO: there has to be a better way to do this :|
        let activeQuest: Selector = NonMatching;
        if (c.quest.active) {
            activeQuest = c.quest.active.quest;
        }
        this.activeQuest = activeQuest;

        // Set the quest associated with this step.
        const expectedQuest = this.quests.get(this.step);
        if (!expectedQuest) {
            this.currentQuest = undefined;
            return;
        }
        if (!this.currentQuest ||
            this.currentQuest.id !== expectedQuest.id) {
            // Lookup the quest for this step
            const q = GetCollection(s.quests, expectedQuest);
            if (!q) {
                throw Error(`failed to find quest ${JSON.stringify(expectedQuest)}`)
            }
            this.currentQuest = q;
        }
    }

    private setStep() {
        const s = vcs.get();

        interface StepStatus {
            step: OnboardStep,
            // tag is the required tag to be present on some
            // globall-completed quest for this step to be considered
            // completed.
            tag?: string | undefined,

            satifisied?: boolean;
        }
        const satisfied: StepStatus[] = [
            {
                step: OnboardStep.PriorToFirstQuest,
                tag: FirstIntroQuestTag,
            },
            {
                step: OnboardStep.FirstQuestDone,
                tag: SecondIntroQuestTag,
            },
            {
                step: OnboardStep.SecondQuestDone,
                tag: ThirdIntroQuestTag
            },
            {
                step: OnboardStep.ThirdQuestDone,
                // No completion step here so its automatically done.
            },
            {
                step: OnboardStep.Complete,
                // We have a non-existent tag here so that we can
                // catch this final step and handle it.
                tag: 'onboard-non-existent-tag',
            },
        ];
        satisfied.forEach(sat => {
            // Ignore satisfied steps
            if (sat.satifisied) {
                return;
            }
            if (!sat.tag) {
                sat.satifisied = true;
                return;
            }

            // Snapshot for use in this loop
            const tag = sat.tag;
            s.globalQuest.completed.forEach(q => {
                if (sat.satifisied) {
                    return;
                }
                const quest = GetCollection(s.quests, q);
                if (!quest) {
                    throw Error(`unknown quest ${JSON.stringify(q)}`);
                }
                if (!quest.tag) {
                    return;
                }

                sat.satifisied = !!quest.tag.tags[tag];
            });
        })
        const current = satisfied.find(step => !step.satifisied)
        if (!current) {
            throw Error(`no steps available`);
        }

        this.step = current.step;
    }
}
customElements.define('initial-onboard', InitialOnboard);