import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, GetCollection, IntentType, PrettyName, NewSelector, GetSkillInfo, GetSkillLevel } from 'eventful-increment';
import { StyleBase } from './style-base';
import { vcs } from '../state';

import './character-health'
import { UIResourceNode, gatherSuccessPercent } from './gather-card';
import { UICharacter } from './gather-choices';

export class GatheringIntent extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    private node: UIResourceNode | undefined;
    private char: UICharacter | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts || !this.target ||
            !this.char || !this.node) {
            return html``
        }

        const { current, max, skill } = this.node.resources;

        const name = PrettyName(this.node);

        const level = GetSkillLevel(this.char.skills, skill)
        const chance = gatherSuccessPercent(vcs.get(), this.target, this.node)

        return html`
<div>
    <div>
        Gathering from ${name} with ${skill}
    </div>
    <div>
        At level ${level}, chance to succeed is ${chance}%
    </div>
    <div>
        ${current} / ${max} remain
    </div>
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c || !c.intent) {
            throw Error(`unknown Character or intent: ${JSON.stringify(this.target)}`)
        }

        this.char = c;

        // Handle non-atomic transitions away from this
        if (c.intent.Type !== IntentType.Gathering) {
            this.node = undefined;
            return;
        }

        this.node = GetCollection(s.nodes, c.intent.Aim);
    }
}
customElements.define('gathering-intent', GatheringIntent);