import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, Second, GetCollection, IntentType, PrettyName } from 'eventful-increment';
// tslint:disable-next-line: no-submodule-imports
import { styleMap, StyleInfo } from 'lit-html/directives/style-map.js';
import { StyleBase } from './style-base';
import { vcs } from '../state';

import './character-health'

interface EnemyInfo {
    name: string;
    sel: Selector;
}

export class SpecificTargetedIntent extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
#us {
    grid-area: us;
}

#them {
    grid-area: them;
}

#split {
   display: grid;
   grid-template-columns: 45fr 10fr 45fr;
   grid-template-rows: 100fr;
   grid-gap: 2px;
   grid-template-areas:
     "us       .      them";
   justify-self: center;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;
    private enemies: EnemyInfo[] | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts || !this.enemies) {
            return html``
        }
        return html`
<div id="split">
    <div id="us">
        <character-health .ts="${this.ts}" .target="${this.target}"></character-health>
    </div>
    <div id="them">
        ${this.enemies.map(e => this.enemyRow(e))}
    </div>
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c || !c.intent) {
            throw Error(`unknown Character or intent: ${JSON.stringify(this.target)}`)
        }

        // If we're transitioning between intents, fail gracefully.
        if (c.intent.Type !== IntentType.SpecificTargeted) {
            this.enemies = undefined;
            return;
        }

        const mapped: EnemyInfo[] = c.intent.Aim.map(sel => {
            const e = GetCollection(s.characters, sel);
            if (!e) {
                return {
                    sel,
                    name: '',
                }
            }

            return {
                sel,
                name: PrettyName(e),
            }
        });
        this.enemies = mapped;
    }

    private enemyRow(enemy: EnemyInfo): TemplateResult {
        return html`
<div>
    <character-health
        .ts="${this.ts}"
        .target="${enemy.sel}"></character-health>
    <div>${enemy.name}</div>
</div>
`
    }
}
customElements.define('specific-targeted-intent', SpecificTargetedIntent);