import { PropertyValues, property, html, css } from 'lit-element';
import { Selector, Second, GetCollection, Result, HitResult, ResultType, GetSkillLevel, Skill } from 'eventful-increment';
// tslint:disable-next-line: no-submodule-imports
import { styleMap, StyleInfo } from 'lit-html/directives/style-map.js';
import { StyleBase } from './style-base';
import { vcs } from '../state';

import './number-splat';
import './si-value';

export class CharacterHealth extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
.healthlabel {
    text-shadow: 0px 0px 10px black;
}
#label {
    flex-direction: row;
    display: flex;
    justify-content: center;
    text-shadow: 0px 0px 5px white;
    position: relative;
    top: -20px;
}

/* put our number-splats fixed to the left side slightly offset */
#splats {
    left: 0.3em;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    private current = 0;
    private max = 0;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``
        }
        return html`
<number-splat id="splats" .ts="${this.ts}" .target="${this.target}" .type="${ResultType.Hit}"></number-splat>
<div class="progress">
    <div style=${styleMap(this.barDynamicStyle())} class="bar"></div>
    <div id="label">
        <si-value .value="${this.current}"></si-value>
        <div>/</div>
        <si-value .value="${this.max}"></si-value>
    </div>
</div>  
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        // Fail gracefully if we're passed an InstanceSelector.
        if (!c) {
            this.current = 0;
            this.max = 0;
            return;
        }
        if (!c.combat) {
            throw Error(`invalid Character without combat ${JSON.stringify(this.target)}`)
        }

        const { current } = c.combat.health;
        this.current = current;
        const healthTier = GetSkillLevel(c.skills, Skill.Health);
        this.max = healthTier * 100 // TODO: a better solution
    }

    private barDynamicStyle(): StyleInfo {
        if (this.max === 0) {
            return { width: '0%' };
        }
        return { width: `${(this.current / this.max) * 100}%` };
    }
}
customElements.define('character-health', CharacterHealth); 