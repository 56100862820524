import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, Second, GetCollection, IntentType, PrettyName, IntentAttrs, SelectorType } from 'eventful-increment';
import { StyleBase } from './style-base';
import { vcs } from '../state';

import './outline-box';
import './quest-choices';
import './farm-choices';
import './gather-choices';
import { Cards } from './svg-icon';
import { Template } from 'lit-html';

export class CharacterChoices extends StyleBase {

    // TODO: use it or lose it
    static get styles() {
        return [
            ...super.styles,
            css`
.wrapper {
    min-height: 768px;
}

h4 {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header > .header-title {
    margin-right: 0.25em;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``
        }
        return html`
<outline-box class="tabs">
    <div class="wrapper">${this.content()}</div>
</outline-box>
`;
    }

    private content(): TemplateResult {
        if (!this.target ||
            this.target.type === SelectorType.NonMatching) {
            return html`
<outline-box class="tabs">
    <div>Select a character</div>
</outline-box>
            `;
        }

        return html`
<input id="tab1" type="radio" name="tabs">
    <label for="tab1">Actions</label>

    <input id="tab3" type="radio" name="tabs" checked>
    <label for="tab3">
        <span>Quests</span>
        <svg-icon .icon="${Cards.Quest}"></svg-icon>
    </label>

    <input id="tab4" type="radio" name="tabs">
    <label for="tab4">
        <span>Loadout</span>
        <svg-icon .icon="${Cards.Loadout}"></svg-icon>
    </label>

    <div class="content" id="content1">
        <h4 class="header">
            <span class="header-title">Farm</span>
            <svg-icon .icon="${Cards.Mob}"></svg-icon>
        </h4>
        <farm-choices
            .ts="${this.ts}"
            .target="${this.target}"></farm-choices>

        <h4 class="header">
            <span class="header-title">Gather</span>
            <svg-icon .icon="${Cards.Gather}"></svg-icon>
        </h4>
        <gather-choices
            .ts="${this.ts}"
            .target="${this.target}"></gather-choices>
    </div>
    <div class="content" id="content3">
        <quest-choices
            .ts="${this.ts}"
            .target="${this.target}"></quest-choices>
    </div>
    <div class="content" id="content4">
        <h4 class="header">
            <span class="header-title">Consumables</span>
        </h4>
        <loadout-list
            .ts="${this.ts}"
            .subject="${this.target}"></loadout-list>
    </div>
`
    }
}
customElements.define('character-choices', CharacterChoices);