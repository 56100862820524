import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, Second, GetCollection, IntentType, PrettyName, IntentAttrs, NonMatching, NewAbsoluteSelector, ResultType } from 'eventful-increment';
import { StyleBase } from './style-base';
import { vcs } from '../state';
import log from '../logger';

import './outline-box';
import './number-splat';

import { IncEventType, NewIncEvent } from './character-list';

interface QuestDetails {
    name: string;
    stepDescription: string;
}

export class PlayerCharacter extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}

#quest-line {
    margin-left: 1em;
}

#reward-row {
    height: 1em;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    @property()
    private selected: Selector | undefined;

    private name: string | undefined;
    private intentMessage: string = '';

    private currentQuest: QuestDetails | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts || !this.target) {
            return html``
        }
        return html`
<outline-box @click="${this.onClick}" .focused="${this.isSelected}">
    <div>${this.name}</div>
    <div>${this.intentMessage}</div>
    <div id="quest-line">${this.questLine}</div>
    <!-- TODO: include some information about intent nicely separated/indented -->
    <div id="reward-row">
        <number-splat
            .ts="${this.ts}"
            .target="${this.target}"
            .type="${[ResultType.Reward, ResultType.Effect]}"
        ></number-splat>
    </div>
</outline-box>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c || !c.intent) {
            throw Error(`unknown Character or intent: ${JSON.stringify(this.target)}`)
        }

        this.name = PrettyName(c);
        this.intentMessage = this.determineIntentMessage(c.intent);

        const quest = c.quest;
        if (quest && quest.active !== undefined) {
            const definition = GetCollection(s.quests,
                quest.active.quest);
            if (!definition) {
                throw Error(`unknown quest ${JSON.stringify(quest.active.quest)}`)
            }
            const step = definition.steps[quest.active.step];
            let stepDescription = '';
            if (step) {
                stepDescription = step.description;
            }

            this.currentQuest = {
                name: PrettyName(definition),
                stepDescription,
            }
        } else {
            this.currentQuest = undefined;
        }
    }

    private get isSelected(): boolean {
        return !!this.target && !!this.selected &&
            this.selected.id === this.target.id;
    }

    private onClick() {
        const char = GetCollection(vcs.get().characters,
            this.target || NonMatching);
        if (!char) {
            log.debug('failed to find ', this.target);
            return;
        }
        const event = NewIncEvent({
            type: IncEventType.SelectCharacter,
            abs: NewAbsoluteSelector(char),
        })
        this.dispatchEvent(event);
    }

    private determineIntentMessage(intent: IntentAttrs): string {
        if (this.currentQuest) {
            return `Completing ${this.currentQuest.name}`
        }

        const s = vcs.get();
        switch (intent.Type) {
            case IntentType.Standby:
                return 'Waiting Around';
            case IntentType.FarmingMob:
                const farm = GetCollection(s.characters, intent.Aim);
                if (!farm) {
                    throw Error(`cannot find aim of: ${JSON.stringify(intent)}`);;
                }
                return `Farming ${PrettyName(farm)}`;
            case IntentType.SpecificTargeted:
                // TODO: quests steps could have 'descriptions'
                // associated with them.
                // Then again, it might make sense to have those machine-generated
                // based on the Step.
                return 'Clearing a pack'
            case IntentType.Gathering:
                const gather = GetCollection(s.nodes, intent.Aim);
                if (!gather) {
                    throw Error(`cannot find aim of: ${JSON.stringify(intent)}`);;
                }
                return `Gathering from ${PrettyName(gather)}`
            default:
                return '???';
        }
    }

    private get questLine(): TemplateResult {
        if (!this.currentQuest) {
            return html``;
        }
        return html`${this.currentQuest.stepDescription}`;
    }
}
customElements.define('player-character', PlayerCharacter);