import { property, html, css, PropertyValues, TemplateResult } from 'lit-element';
import { StyleBase } from './style-base';
import { AbsoluteSelector, GetCollection, ConsumableSlots, State, GetCommodity, CommodityKey, Commodity, Selector } from 'eventful-increment';

import { UICharacter } from './farm-choices';
import { vcs } from '../state';

import './outline-box'
import { ReadonlyDeep } from '../types/readonly-deep';
import { ChoiceList, Sorting, Sortable } from './choice-list';
// tslint:disable-next-line:no-submodule-imports
import { classMap } from 'lit-html/directives/class-map';
import { NewIncEvent, IncConsumableSlotSetDetail, IncEventType } from './character-list';

export type UICommodity = ReadonlyDeep<Commodity>;

type SlotSelect = (slot: number | undefined) => void;

function consumablesList(s: State,
    selected: number | undefined, select: SlotSelect,
    slots: ReadonlyDeep<ConsumableSlots>): TemplateResult[] {

    if (!s) {
        throw Error('gotta use dat s')
    }

    return slots.set.map((slot, i) => {
        if (!slot) {
            return html`
<outline-box class="slot"
    .focused="${selected === i}"
    @click=${() => select(i)}>
    Empty
</outline-box>
`
        }

        const commodity = GetCommodity(s.inventory.commodities, slot)
        return html`
<outline-box class="slot"
    .focused="${selected === i}"
    @click=${() => select(i)}>
    <div class="row">
        <svg-icon .icon="${slot}"></svg-icon>
        <div>×${commodity.count}</div>
    </div>
    <div>${slot}</div>
</outline-box>
`})
}

export class LoadoutList extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}

#consumables-list {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.slot {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2em;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private subject: AbsoluteSelector | undefined;

    private details: UICharacter | undefined;

    @property()
    private selectedSlot: number | undefined;

    public render() {
        if (!this.ts || !this.details) {
            return html``
        }

        const loadout = this.details.loadout;
        if (!loadout) {
            return html`no loadout present`
        }

        const s = vcs.get();

        return html`
<div id="consumables-list">
    ${consumablesList(s,
            this.selectedSlot,
            i => this.selectSlot(i), loadout.consumables)}
</div>
<consumable-choices
    ?hidden="${this.selectedSlot === undefined}"
    .ts="${this.ts}"
    .selectedSlot=${this.selectedSlot}
    .target="${this.subject}"
    @consume-slot-set="${() => this.selectedSlot = undefined}"
    ></consumable-choices>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        // It is valid for us not to have a target
        // so we handle that appropriately by removing the card.
        if (!this.subject) {
            this.details = undefined;
            return;
        }

        const s = vcs.get();
        this.details = GetCollection(s.characters, this.subject)
    }

    private selectSlot(i: number | undefined) {
        // Allow un-selecting by selecting twice.
        if (i === this.selectedSlot) {
            this.selectedSlot = undefined;
            return;
        }
        this.selectedSlot = i;
    }
}
customElements.define('loadout-list', LoadoutList);

enum ConsumableHeaders {
    Name = 'Name',
    Quantity = '#',
    Effects = 'Effects',
}

// TODO: You should probably not live here :|
export class ConsumableChoices extends ChoiceList {

    static get styles() {
        return [
            ...super.styles,
            css`
#clear-wrapper {
    display: flex;
}

#clear-button {
    margin-left: auto;
    margin-right: 1em;
    margin-bottom: 0.5em;
}

.select-col {
    min-width: 5em;
}

.table-row {
    word-break: break-all;
}
`
        ];
    }
    private commodities: UICommodity[] = [];

    @property()
    private activeChar: Selector | undefined;

    /**
     * preSelected is the row which should display a selection button
     */
    @property()
    private preSelected: number | undefined;

    @property()
    private selectedSlot: number | undefined;

    constructor() {
        super();
    }

    public render() {
        // Normally, we wouldn't overwrite the render of this element.
        // However, we also want to support clearing a slot so we
        // decorate the element.
        const base = super.render();
        if (!base) {
            return base;
        }

        return html`
<div id="choices">
    <div id="clear-wrapper">
        <button id="clear-button"
            @click="${() => this.setSlot(undefined)}">Clear</button>
    </div>
    ${base}
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const targetChange = changedProperties.get('target');
        if (targetChange) {
            this.activeChar = undefined;
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);

        if (!c || !c.loadout) {
            // No loadout means we can't do anything with this Character
            this.commodities = [];
            return;
        }

        const { commodities } = s.inventory;
        this.commodities = Object.keys(commodities)
            .map(key => GetCommodity(commodities, key as CommodityKey))
            .filter(commodity => !!commodity.consumables)
    }

    protected defaultSort(): Sorting {
        return { name: '', ascending: false, }
    }

    protected header(): Sortable[] {
        return [
            {
                name: ConsumableHeaders.Name,
                sortBy: () => false,
            },
            {
                name: ConsumableHeaders.Quantity,
                sortBy: () => false,
            },
            {
                name: ConsumableHeaders.Effects,
                sortBy: () => false,
            },
            // Placeholder for button
            {
                name: '',
                sortBy: () => false,
            }
        ];
    }

    protected row(index: number): TemplateResult | undefined {
        // Ensure bounds are valid
        if (index >= this.commodities.length) {
            return undefined;
        }

        const commodity = this.commodities[index];
        // TODO: filter for consumables in `update`
        if (!commodity.consumables) {
            throw Error('consumables MUST be present on commodity')
        }
        const effects = commodity.consumables.consume
            .effect.map(effect => html`
<effect-item .effect="${effect}" .short=${true}></effect-item>    
`)
        // TODO: handle requirements :|

        // TODO: implement onclick
        return html`
        <tr class="table-row"
            @click="${() => this.preSelected = index}">
            <td></td>
            <td>
                <span>${commodity.key}</span>
                <svg-icon .icon="${commodity.key}"></svg-icon>
            </td>
            <td>${commodity.count}</td>
            <td>${effects}</td>
            <td class="select-col">
                ${(this.preSelected === index) ?
                html`<button
                    @click=${() => this.setSlot(index)}>Use</button>` :
                undefined
            }
            </td>
        </tr>
        `
    }

    protected card(sel: AbsoluteSelector | undefined): TemplateResult {
        return html`
<farm-card class="mob"
    .ts="${this.ts}"
    .target="${this.target}"
    .subject="${sel}"
    .activeChar="${this.activeChar}"></farm-card>
`
    }

    private setSlot(usingIndex: number | undefined) {
        // Ignore selection attempts without a slot selected
        if (this.selectedSlot === undefined) {
            return;
        }
        if (!this.target) {
            throw Error('cannot set slot with invalid target')
        }

        let using: CommodityKey | undefined;
        if (usingIndex !== undefined) {
            using = this.commodities[usingIndex].key;
        }

        const detail: IncConsumableSlotSetDetail = {
            type: IncEventType.ConsumableSlotSet,
            subject: this.target,
            slot: this.selectedSlot,
            using,
        }
        this.dispatchEvent(NewIncEvent(detail));
    }
}
customElements.define('consumable-choices', ConsumableChoices);