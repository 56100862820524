import { PropertyValues, html, TemplateResult, css, property } from 'lit-element';
import { GetCollection, IntentType, PrettyName, NewAbsoluteSelector, AbsoluteSelector, Character, CharactersWithLabel, Label, RewardXPSum, GetCombatLevel, HasLabel, CheckRequirementsGroup, Selector } from 'eventful-increment';
import { vcs } from '../state';

import './farm-card';
import './si-value';
import { ReadonlyDeep } from '../types/readonly-deep';
import { CombatShorthand } from './quest-card';
import { InternalClass } from './outline-box';

// tslint:disable-next-line:no-submodule-imports
import { classMap } from 'lit-html/directives/class-map';

import { ChoiceList, Sortable, Sorting } from './choice-list';

// UICharacter is a Character that can be used for display
// purposes. It is immutable. This reference should be
// updated whenever this card changes.
export type UICharacter = ReadonlyDeep<Character>;

/**
 * MobSort is used by table headers for use-facing display
 * as well as our internal sort reference.
 * 
 * By tying these together, things get easier.
 */
enum MobSort {
    Combat = 'Combat',
    Experience = 'Exp',
}

function byCombatLevel(a: UICharacter, b: UICharacter): number {
    return GetCombatLevel(a.skills) - GetCombatLevel(b.skills)
}

function byExperience(a: UICharacter, b: UICharacter): number {
    // Note that this is extremely slow; we should be careful not to
    // sort very long lists like this.
    // TODO: consider increasing performance here.
    return RewardXPSum(a as Character) - RewardXPSum(b as Character)
}

// sortChars performs an in-place sort of the provided characters
// using the providing Sort-order
function sortChars(s: MobSort | string, ascending: boolean,
    chars: UICharacter[]) {
    chars.sort((a, b) => {
        switch (s) {
            case MobSort.Combat:
                return byCombatLevel(a, b);
            case MobSort.Experience:
                return byExperience(a, b);
            default:
                throw Error(`unknown MobSort: ${JSON.stringify(s)}`);
        }
    });

    if (!ascending) {
        chars.reverse()
    }
}

export class FarmChoices extends ChoiceList {
    private chars: UICharacter[] = [];

    @property()
    private activeChar: Selector | undefined;

    constructor() {
        super();
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target) {
            throw Error('cannot update with false-y target')
        }

        const targetChange = changedProperties.get('target');
        if (targetChange) {
            this.activeChar = undefined;
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);

        if (!c || !c.intent) {
            // No intent means we can't do anything with this Character
            this.chars = [];
            return;
        }

        if (c.intent.Type === IntentType.FarmingMob) {
            this.activeChar = c.intent.Aim
        }

        // Only do the expensive work of filtering characters if
        // we NEED to or if we haven't done so yet.
        if (!!changedProperties.get('by') ||
            this.chars.length === 0) {

            this.chars = CharactersWithLabel(s, Label.Farmable)
                .map(sel => GetCollection(s.characters, sel))
                .filter(char => char !== undefined) as UICharacter[];

            // Apply our sorting semantics
            if (!this.by.name) {
                return;
            }
            sortChars(this.by.name, this.by.ascending, this.chars);
            this.chars = this.chars.slice();
        }

    }

    protected defaultSort(): Sorting {
        return {
            name: MobSort.Combat,
            ascending: true,
        }
    }

    protected header(): Sortable[] {
        return [
            {
                name: MobSort.Combat,
                sortBy: () => true,
            },
            {
                name: MobSort.Experience,
                sortBy: () => true,
            },
            {
                name: 'Status',
                sortBy: () => false,
            }
        ];
    }

    protected row(index: number): TemplateResult | undefined {
        // Ensure bounds are valid
        if (index >= this.chars.length) {
            return undefined;
        }

        const char = this.chars[index];
        const name = PrettyName(char);

        // We have to cast here due to the readonly constraint
        // we work under. This is unpalatable but there is no easy
        // way around it.
        const totalXP = RewardXPSum(char as Character);

        let farmingString = '';
        if (this.activeChar && char.id === this.activeChar.id) {
            farmingString = 'Farming';
        }

        const s = vcs.get();

        const players: Character[] = CharactersWithLabel(s, Label.Player)
            .map(c => GetCollection(s.characters, c) as Character);

        // There is an issue here where we're going to allow Characters
        // who shouldn't be able to fight being allowed to see this :|
        const unmetRequirements = CheckRequirementsGroup(s,
            char as Character, ...players);
        const disabled = unmetRequirements.length > 0;
        const classes: InternalClass = {
            'disabled': disabled,
            // Shake on desktop hover or mobile tap;
            // this is provided by our customer paper.css
            // Tap behavior is a little funky but gets the job done.
            'shake-hover': disabled,
        };

        return html`
        <tr
            @click="${() => disabled ? null : this.selected = NewAbsoluteSelector(char)}"
            class=${classMap(classes)}>
            <td>${name}</td>
            <td>${CombatShorthand(char)}</td>
            <td>
                <si-value .value="${totalXP}"></si-value>xp
            </td>
            <td>${farmingString}</td>
        </tr>
        `
    }

    protected card(sel: AbsoluteSelector | undefined): TemplateResult {
        return html`
<farm-card class="mob"
    .ts="${this.ts}"
    .target="${this.target}"
    .subject="${sel}"
    .activeChar="${this.activeChar}"></farm-card>
`
    }
}
customElements.define('farm-choices', FarmChoices);