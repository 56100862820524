import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, Second, GetCollection, IntentType, PrettyName, IntentAttrs, AvailableQuests, NewAbsoluteSelector, AbsoluteSelector, NonMatching, StepType, FightStep, State, QuestStep, GetSkillLevel, Skill, Quest, GetCombatLevel, Reward, Requirement, GatherStep, PrettyError, CheckRequirements, CheckRequirementsGroup, Character, } from 'eventful-increment';
import { StyleBase } from './style-base';
import { vcs } from '../state';
import { NewIncEvent, IncQuestStartDetail, IncEventType } from './character-list';
import { UICharacter } from './farm-choices';
import { UIQuest, QuestInProgress } from './quest-choices';

import './reward-row';
import './requirement-row';
import { ReadonlyDeep } from '../types/readonly-deep';
import { Cards } from './svg-icon';
import { InternalClass } from './outline-box';
// tslint:disable-next-line:no-submodule-imports
import { classMap } from 'lit-html/directives/class-map';

interface Summary {
    title: string;
    lines: TemplateResult;
}

export function StepSummary(s: State, step: QuestStep): Summary {
    switch (step.type) {
        case StepType.Fight:
            return fightSummary(s, step);
        case StepType.Mock:
            return mockSummary();
        case StepType.Gather:
            return gatherSummary(s, step);
        default:
            throw Error(`unmatched step: ${JSON.stringify(step)}`)
    }
}

function mockSummary(): Summary {
    return {
        title: 'Mock',
        lines: html``,
    };
}

export function CombatShorthand(char: UICharacter): string {
    const combatLevel = GetCombatLevel(char.skills);
    return `${combatLevel}`
}

function fightSummary(s: State, step: FightStep): Summary {
    const groups = step.enemies
        .map(sel => GetCollection(s.characters, sel))
        .reduce((accum, char) => {
            if (!char || !char.combat) {
                throw Error(`expected enemy not found or has no combat ${JSON.stringify(step.enemies)}`)
            }
            const key = `${PrettyName(char)}(${CombatShorthand(char)})`
            const prev = accum.get(key) || 0;
            accum.set(key, prev + 1);

            return accum;
        }, new Map<string, number>())

    const builder: string[] = [];
    groups.forEach((count, attribs) => {
        builder.push(`${count}x ${attribs}`);
    })
    const lines = html`
<ul>
    ${builder.map(b => html`<li>${b}</li>`)}
</ul>
`;
    return {
        title: 'Fight',
        lines,
    }
}

function gatherSummary(s: State, step: GatherStep): Summary {
    const node = GetCollection(s.nodes, step.node)
    if (!node) {
        throw PrettyError`unknown node: ${step.node}`
    }

    return {
        title: 'Gather',
        lines: html`
<div>
    Gathering from ${PrettyName(node)} with ${node.resources.skill}
</div>
        `,
    };
}

export class QuestCard extends StyleBase {
    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    display: block;
}

#header-line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

ol {
    margin: 0px;
}

#buttons {
    display: flex;
    flex-direction: row-reverse;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    @property()
    private quest: UIQuest | undefined;

    @property()
    private activeQuest: Selector | undefined;

    private name: string = '';
    private description: string = '';
    private inProgress: boolean = false;

    // We retain a readonly reference to be able to check requirements
    private char: UICharacter | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts || !this.quest) {
            return html``
        }
        // Snapshot non-undefined state for use in template
        const quest = this.quest;
        return html`
<div class="card">
    <div class="card-header">
        <div id="header-line">
            <svg-icon .icon="${Cards.Quest}"></svg-icon>
            <span>&nbsp; Quest</span>
        </div>
    </div>
    <div class="card-body">
        <h4 class="card-title">${this.name}</h4>
        <h5 class="card-subtitle">${this.description}</h5>
        <p class="card-text">${this.info()}</p>        
        <div id="buttons">${this.questButton(quest)}</div>
        <p class="card-text">${this.steps()}</p>
    </div>
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target || !this.quest) {
            this.name = '';
            this.description = '';
            this.inProgress = false;
            return;
        }

        const s = vcs.get();
        this.char = GetCollection(s.characters, this.target);

        // Shorthand, easier to read
        const q = this.quest;
        this.name = PrettyName(q);
        this.description = q.display ? q.display.description : '';
        this.inProgress = QuestInProgress(q, this.activeQuest);
    }

    private questButton(details: UIQuest): TemplateResult {
        let unmetRequirements = false;
        if (!!this.char) {
            const s = vcs.get();
            const unmet = CheckRequirementsGroup(s,
                this.quest as Quest, this.char as Character)
            unmetRequirements = unmet.length > 0
        }
        const classes: InternalClass = {
            'disabled': unmetRequirements,
            'shake-hover': unmetRequirements,
        };

        const msg = this.inProgress ? 'Restart' : 'Start';
        return html`
<button
    class=${classMap(classes)}
    @click="${() => this.startQuest(details)}">
${msg}
</button>
`
    }

    private startQuest(q: UIQuest) {
        if (!this.target) {
            throw Error('cannot start quest with invalid target')
        }
        const detail: IncQuestStartDetail = {
            type: IncEventType.QuestStart,
            subject: this.target,
            quest: NewAbsoluteSelector(q),
        }
        this.dispatchEvent(NewIncEvent(detail));
    }

    private info(): TemplateResult {
        if (!this.quest) {
            return html``;
        }
        const s = vcs.get();
        if (!this.quest) {
            throw Error(`cannot summarize unknown quest ${JSON.stringify(this.quest)}`)
        }

        let rewards: Readonly<Array<ReadonlyDeep<Reward>>> = [];
        if (this.quest.reward) {
            rewards = this.quest.reward.rewards;
        }
        let requirements: Readonly<Array<ReadonlyDeep<Requirement>>> = [];
        if (this.quest.requires) {
            requirements = this.quest.requires.requirements;
        }

        // TODO: we can add `text-decoration: line-through`
        // to indicate completed steps of the current quest.
        return html`
${requirements.length > 0 ?
                html`
<div class="collapsible">
    <input id="collapsible2" type="checkbox" name="collapsible" checked>
    <label for="collapsible2">Requirements</label>
    <div class="collapsible-body">
        <requirement-set
            .ts="${this.ts}"
            .requirements="${requirements}"
        ></requirement-set>
    </div>
</div>
` : html``}
<div class="collapsible">
    <input id="collapsible3" type="checkbox" name="collapsible" checked>
    <label for="collapsible3">Rewards</label>
    <div class="collapsible-body">
        <reward-set class="splat reward"
            .ts="${this.ts}"
            .rewards="${rewards}"></reward-set>
    </div>
</div>
`
    }

    private steps(): TemplateResult {
        if (!this.quest) {
            return html``;
        }
        const s = vcs.get();
        if (!this.quest) {
            throw Error(`cannot summarize unknown quest ${JSON.stringify(this.quest)}`)
        }
        const steps = this.quest.steps
            .map(readonlyStep => {
                const step = readonlyStep as QuestStep
                const summary = StepSummary(s, step)
                return html`
<div>${summary.title}</div>
<div>${summary.lines}</div>
`
            })

        // TODO: we can add `text-decoration: line-through`
        // to indicate completed steps of the current quest.
        return html`
<div class="collapsible">
    <input id="collapsible1" type="checkbox" name="collapsible">
    <label for="collapsible1">Steps</label>
    <div class="collapsible-body">
        <ol>
            ${steps.map(step => html`<li>${step}</li>`)}
        </ol>
    </div>
</div>
`
    }
}
customElements.define('quest-card', QuestCard);