import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { Selector, Second, GetCollection, IntentType, PrettyName, IntentAttrs, AvailableQuests, NewAbsoluteSelector, AbsoluteSelector, NonMatching, StepType, FightStep, State, QuestStep, GetSkillLevel, Skill, Character, NewLivingSelector, xpToLevel, } from 'eventful-increment';
import { StyleBase } from './style-base';
import { vcs } from '../state';
import { NewIncEvent, IncQuestStartDetail, IncEventType, IncIntentChangeDetail } from './character-list';
import { UICharacter } from './farm-choices';
import { CombatShorthand } from './quest-card';

import './reward-row';
import { Cards } from './svg-icon';

export class FarmCard extends StyleBase {
    static get styles() {
        return [
            ...super.styles,
            css`

:host {
    display: block;
}

ol {
    margin: 0px;
}

.card-header {
    display: flex;
}

#header-line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#header-stats {
    margin-left: auto;
}

#gutter {
    display: flex;
    align-items: center;
}

#description {
    margin-right: 1em;
}

#buttons {
    margin-left: auto;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    @property()
    private subject: AbsoluteSelector | undefined;

    // TODO: we need to handle active character :|
    @property()
    private activeChar: Selector | undefined;

    private details: UICharacter | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts || !this.details) {
            return html``
        }
        // Snapshot non-undefined state for use in template
        const details = this.details;
        const name = PrettyName(details);
        let description = '';
        if (details.display) {
            description = details.display.description;
        }

        const { Health } = details.skills;
        let maxHPString = '';
        if (Health) {
            maxHPString = `${xpToLevel(Health.xp) * 100}hp`
        }

        return html`
<div class="card">
    <div class="card-header">
        <div id="header-line">
            <svg-icon .icon="${Cards.Mob}"></svg-icon>
            <span>&nbsp; Mob</span>
        </div>
        <div id="header-stats">${CombatShorthand(details)}</div>
    </div>
    <div class="card-body">
        <h4 class="card-title">
            <div>${name}</div>
            <div>${maxHPString}</div>
        </h4>
        <h5 class="card-subtitle"></h5>
        <p class="card-text">${this.textSection()}</p>
        <div id="gutter">
            <div id="description">${description}</div>
            <div id="buttons">${this.farmButton(details)}</div>
        </div>
    </div>
</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        // It is valid for us not to have a target
        // so we handle that appropriately by removing the card.
        if (!this.target || !this.subject) {
            this.details = undefined;
            return;
        }

        const s = vcs.get();
        this.details = GetCollection(s.characters, this.subject)
    }

    private farmButton(details: UICharacter): TemplateResult {
        if (this.activeChar && details.id === this.activeChar.id) {
            return html`
<div>
Farming
</div>`
        }
        return html`
<button
    @click="${() => this.startFarming(details)}">
Farm
</button>
`
    }

    private startFarming(c: UICharacter) {
        if (!this.target) {
            throw Error('cannot farm with with invalid target')
        }
        const detail: IncIntentChangeDetail = {
            type: IncEventType.IntentChange,
            target: this.target,
            intent: {
                Type: IntentType.FarmingMob,
                Aim: NewAbsoluteSelector(c),
            }
        }
        this.dispatchEvent(NewIncEvent(detail));
    }

    private textSection(): TemplateResult {
        if (!this.details || !this.details.reward) {
            return html``;
        }
        const { rewards } = this.details.reward;


        return html`
<div class="collapsible">
    <input id="collapsible1" type="checkbox" name="collapsible" checked>
    <label for="collapsible1">Rewards</label>
    <div class="collapsible-body">
        <reward-set
            .ts="${this.ts}"
            .rewards="${rewards}"></reward-set>
    </div>
</div>
`
    }
}
customElements.define('farm-card', FarmCard);