import { property, html, css, TemplateResult } from 'lit-element';
import { StyleBase } from './style-base';
import { Reward, RewardType, XPReward, CharacterReward, CommodityReward } from 'eventful-increment';

import './svg-icon';
import './si-value';

function renderCharacter(_: CharacterReward): TemplateResult {
    return html`<div>Recruit!</div>`;
}

function renderExperience(reward: XPReward): TemplateResult {
    const { xp, skill } = reward;
    return html`
<si-value class="magnitude"
    .value="${xp}"></si-value>
<svg-icon
    .gain="${true}"
    .icon="${skill}"></svg-icon>
`;
}

function renderCommodity(reward: CommodityReward): TemplateResult {
    const { count, key } = reward.commodity;
    return html`
<si-value class="magnitude"
    .value="${count}"></si-value>
&nbsp;${key}&nbsp;
<svg-icon
    .gain="${true}"
    .icon="${key}"></svg-icon>
`;
}

// RewardSet displays Rewards in a standard format.
export class RewardSet extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    position: relative;
    display: block;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;
}

.magnitude {
    padding-right: 0.1em;
}

.reward {
    display: flex;
    align-items: center;
    padding-right: 0.4em;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private rewards: Array<Readonly<Reward>> | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``
        }
        // TODO: implement more types of Reward!
        return html`
<div>
    ${this.renderXP()}
</div>`;
    }

    private renderXP(): TemplateResult {
        if (!this.rewards || this.rewards.length === 0) {
            return html``;
        }
        // TODO: we should do the grouping once on update...
        const groups: Map<RewardType, Reward[]> = new Map();
        this.rewards.forEach(reward => {
            let prev = groups.get(reward.type);
            if (!prev) {
                prev = [];
            }
            // This modifies in-place for pre-existing but we still
            // need to handle our pre-existing elements.
            prev.push(reward);
            groups.set(reward.type, prev);
        });

        // TODO: is there a better way to do this?
        const rendered: TemplateResult[] = [];
        groups.forEach(rewards => {
            const block = rewards.map(reward => {
                switch (reward.type) {
                    case RewardType.Experience:
                        return renderExperience(reward);
                    case RewardType.Character:
                        return renderCharacter(reward);
                    case RewardType.Commodity:
                        return renderCommodity(reward);
                    default:
                        // Unknown rewards are fine to not show; these
                        // are not a critical error so we won't throw.
                        return html``;
                }
            });
            rendered.push(html`
<div class="row">
    ${block.map(r => html`<div class="reward">${r}</div>`)}
</div>
`);
        })

        return html`${rendered}`
    }
}

customElements.define('reward-set', RewardSet);