import { PropertyValues, property, html, css, TemplateResult } from 'lit-element';
import { StyleBase } from './style-base';
import { Time, ResultType, Result, HitResult, Selector, Second, Skill, Reward, Rewarded, RewardType, GetCollection, GetSkillInfo, xpToLevel, levelToXP, SkillInfo } from 'eventful-increment';
import { vcs, CauseEffect } from '../state';

import log from '../logger'

import './svg-icon';
import './si-value';

interface XPInfo {
    currentLevel: number;
    xpThisLevel: number;

    fullDelta: number;
    percentComplete: number;

    name: string;
}

function xp(info: SkillInfo): XPInfo {
    const currentXP = info.xp;
    const currentLevel = xpToLevel(info.xp);
    // Going through this fetches us the minimum
    // experience for our current level.

    const currentLevelXP = levelToXP(currentLevel);
    const nextLevelXP = levelToXP(currentLevel + 1);
    const fullDelta = nextLevelXP - currentLevelXP;

    const xpThisLevel = currentXP - currentLevelXP;
    const percentComplete = AsPercent(xpThisLevel / fullDelta);

    return {
        currentLevel, fullDelta,
        xpThisLevel, percentComplete,
        name: info.type,
    }
}

function AsPercent(ratio: number): number {
    const percent = (ratio * 100);
    const rounded = Math.floor(percent * 10) / 10
    return rounded
}

export class SkillLevel extends StyleBase {

    static get styles() {
        return [
            ...super.styles,
            css`
:host {
    position: relative;
}

#wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#number-set {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.1em;
}

#divider {
    padding-left: 0.2em;
    padding-right: 0.2em;
}

#name {
    display: flex;
    justify-content: center;
    padding-right: 30%;
}
`
        ];
    }

    @property()
    private ts = 0;

    @property()
    private target: Selector | undefined;

    @property()
    private skill: Skill | undefined;

    private xp: XPInfo | undefined;

    constructor() {
        super();
    }

    public render() {
        if (!this.ts) {
            return html``;
        }
        if (!this.xp) {
            return html`???`;
        }

        const {
            currentLevel, fullDelta, percentComplete,
            name,
        } = this.xp;
        return html`
<div id="wrapper">
    <svg-icon
        .large="${true}"
        .icon="${this.skill}"></svg-icon>
    <div id="number-set">
        <div id="level">Level ${currentLevel}</div>
        <div class="row">
            <div>${percentComplete}%</div>
        </div>
        <div class="row">
            <div id="divider">of</div>    
            <si-value .value="${fullDelta}"></si-value>
            <div>xp</div>
        </div>
    </div>
</div>
<div id="name">${name}</div>
`;
    }

    public update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (!this.target || !this.skill) {
            return;
        }

        const s = vcs.get();
        const c = GetCollection(s.characters, this.target);
        if (!c) {
            log.warn(`failed to find Character: ${JSON.stringify(this.target)}`);
            return;
        }

        const info = GetSkillInfo(c.skills, this.skill);
        if (!info) {
            // No info is fine; the skill may not be unlocked
            return;
        }
        this.xp = xp(info);
    }
}
customElements.define('skill-level', SkillLevel);